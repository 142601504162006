import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Spin
} from 'antd';
import Firebase from "../../utils/firebaseConfig";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import img_crear from '../../assets/images/eventos/energia_del_futuro/02 BotonRegistrate.png';
import RegistroCompletoAplicacionNom012 from './RegistroCompletoAplicacionNom012';

import '../../assets/css/registro2.css';

import flujo from '../../assets/images/antp_Nom012_BannerContenido.png';
import img_logo_antp from '../../assets/images/eventos/nom_015/antp_nom015_170ct_registro.jpg';
import img_logo_antp_movil from '../../assets/images/eventos/nom_015/antp_nom015_17oct_portada-.jpg';
import logo_central from '../../assets/images/webinars/antp_LogoUsuarios.png';

import { mail_registro_confirmacion } from '../../utils/ConexionServidor';
import HeaderIngenieriaVehicular from "../master/header/HeaderIngenieriaVehicular";
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

const nom_evento = '1-coloquio-transporte-multimodal';
const app = new Firebase();

let correo_registrado = ''; let pass_registrado = '';
const { Option } = Select;

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description:
      texto,
    duration: 10,
  });
};

const Signup = ({ setsignup, history }) => {
  const [error, seterror] = useState("");
  const [confirmacion, setConfirmacion] = useState(false);
  const [otro_medio, setOtroMedio] = useState(false);
  const [facturar, setFacturar] = useState(false);
  const [forma_de_pago, setFormaDePago] = useState('');
  const [cargando_registro, setCargandoRegistro] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_recaptcha = React.createRef();

  const elegirMedio = value => {


    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      openNotificationWithIcon('warning', 'Advertencia', 'Confirma no ser un robot');
      return;
    }

    let recaptcha = ref_recaptcha.current.getValue();

    setCargandoRegistro(true);

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };

    values.password = values.ap_paterno.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    const { correo, password } = values;

    let contra = values.password;

    correo_registrado = correo;
    pass_registrado = password;

    //delete values.password;
    delete values.aceptar_terminos;
    delete values.confirm;

    values.nombre_curso = nom_evento;

    if (values.medio_enterado === 'Otro') {
      values.medios = values.otro_medio;
      delete values.otro_medio;
    } else {
      values.medios = values.medio_enterado;
    }

    values.nombre1 = values.nombre;
    values.apellidos = values.ap_paterno;
    values.apellidos = values.ap_materno;
    values.procedencia = '';
    values.fecha_nacimiento1 = '';
    values['g-recaptcha-response'] = recaptcha;
    values.otro_procedencia = values.empresa;
    values.pass = contra;


    console.log(values);


    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          openNotificationWithIcon('success', '', data);
          /*app.escribir(nom_evento+'/usuarios',undefined,values, () => {
              //history.push('/conferencias');
            });*/
          setCargandoRegistro(false);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon('warning', '', data);
          //setConfirmacion(true);
          setCargandoRegistro(false);
          break;
      }
      setCargandoRegistro(false);
    });

  };
  return (<>
  <HeaderIngenieriaVehicular />
    <Row className="contenedor_registro_" style={{ backgroundColor: 'white' }}>
      <Col span={24}>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 24 }} style={{ backgroundColor: 'white' }}>
            <img src={img_logo_antp} style={{ width: '100%' }} />
            
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 0 }} style={{ backgroundColor: 'white' }}>
            <img src={img_logo_antp_movil} style={{ width: '100%' }} />
            
          </Col>
          
        </Row>
        <Row>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 5 }}>
            <Spin spinning={cargando_registro}>
              {!confirmacion ?
                (
                  <Form className="login-form"
                    ref={ref_form}
                    form={form}
                    layout="vertical"
                    name="basic"
                    /*initialValues={{
                      remember: true,
                      nombre: 'Martin',
                      ap_paterno: 'Rodríguez',
                      ap_materno: 'Ayala',
                      correo:'martin@thethree.com.mx',
                      empresa: 'The Three',
                      medio_enterado: 'Facebook',
                      razon_social: 'MARTIN',
                      rfc:'M4T1N',
                    cp:'00000',
                    correo_factura:'martin@facturas.com',
                    uso_cdfi: 'Por definir (P01)'
            
            
                    }}*/
                    onFinish={onFinish}
                  >

                    <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                      <Col span={24}>
                        <br /><br />
                        <p p style={{ textAlign: 'justify', fontSize: '16px', fontWeight: 'bold', color: '#329900', marginBottom:'0px' }}>
                        Te informamos que la contraseña es requerida unicamente para crear una cuenta exclusiva para el webinar y poder interactuar con los panelistas con preguntas o comentarios.
                        </p>
                        <p style={{ textAlign: 'left', fontSize: '22px', fontWeight: 'bold', color: '#112f6d' }}>
                          <br />
                          REGÍSTRATE
                        </p>
                        <hr style={{ backgroundColor: '#26b3fa', border: '1px solid #26b3fa' }} />
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="nombre"
                          label={<span className="estilo_campos">Nombre</span>}
                          rules={[

                            {
                              required: true,
                              message: 'Ingresa tu nombre!',
                            },
                          ]}
                        >
                          <Input autoComplete="nope" />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          name="ap_materno"
                          label={<span className="estilo_campos">Apellidos</span>}
                          initialValues=''
                          rules={[

                            {
                              required: true,
                              message: 'Ingresa tu apellido materno!',
                            },

                          ]}
                        >
                          <Input autoComplete="nope" />
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={2}>
                        <Form.Item
                          name="ap_paterno"
                          label={<span className="estilo_campos">Contraseña</span>}
                          rules={[

                            {
                              required: true,
                              message: 'Ingresa tu apellido paterno!',
                            },
                          ]}
                        >
                          <Input autoComplete="nope" />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          name="correo"
                          label="Correo electrónico"
                          rules={[
                            {
                              type: 'email',
                              message: '...',
                            },
                            {
                              required: true,
                              message: 'Ingresa tu correo electrónico !',
                            },
                          ]}
                        >
                          <Input autoComplete="nope" />
                        </Form.Item>
                      </Col>
                      <Col span={11} offset={2}>
                        <Form.Item
                          name="telefono"
                          label="Teléfono celular"
                          rules={[
                            {
                              required: true,
                              message: 'Ingresa tu número de celular!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          name="empresa"
                          label={<span className="estilo_campos">Empresa</span>}
                          rules={[

                            {
                              required: true,
                              message: 'Ingresa el nombre de tu empresa!',
                            },
                          ]}
                        >
                          <Input autoComplete="nope" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="medio_enterado"
                          label={<span className="estilo_campos">¿Por qué medio te enteraste?</span>}
                          rules={[
                            {
                              required: true,
                              message: 'Elige el medio por el que te enteraste!',
                            },
                          ]}
                        >
                          <Select onChange={elegirMedio} >
                            <Option value="Facebook">Facebook</Option>
                            <Option value="Twitter">Twitter</Option>
                            <Option value="Instagram">Instagram</Option>
                            <Option value="Correo electrónico">Correo electrónico</Option>
                            <Option value="Whatsapp">Whatsapp</Option>
                            <Option value="Otro">Otro</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {otro_medio &&
                          (
                            <Form.Item
                              name="otro_medio"
                              label={<span className="estilo_campos">¿Cuál?</span>}
                              rules={[

                                {
                                  required: true,
                                  message: 'Ingresa el medio por el que te enteraste!',
                                },
                              ]}
                            >
                              <Input autoComplete="nope" />
                            </Form.Item>
                          )
                        }
                      </Col>
                      


                      <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item
                          name="aceptar_terminos"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Acepta el aviso de privacidad'),
                            },
                          ]}
                        >
                          <Checkbox style={{ color: 'black' }}>
                            Acepto el <span onClick={() => { window.open('https://www.antp.org.mx/aviso-de-privacidad', 'Aviso de privacidad', "top=100,left=500,width=600,height=600"); }}>
                              <strong>aviso de privacidad</strong>
                            </span>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <ReCAPTCHA ref={ref_recaptcha}
                          sitekey="6LdbzWkUAAAAAJ0HKwuFITRLb25LQDsL50wkYwO_"
                        />
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <br />
                          <img style={{ width: '150px', padding: '0px', cursor:'pointer' }} src={img_crear} alt="Entrar" onClick={() => {ref_form.current.submit(); forma_de_pago === 'Tarjeta de crédito o débito' && window.open('https://www.paypal.com/paypalme/antpmx/568.40', 'Aviso de privacidad', "top=100,left=500,width=600,height=600") }} />

                          <Button onClick={() => setsignup(false)} type="link" style={{ display: 'none' }}>
                            ¡Ingresa ahora!
                          </Button>
                          <br /><br /><br /><br />
                        </Form.Item>
                      </Col>
                      
                    </Row>
                  </Form>
                )
                :
                (
                  <RegistroCompletoAplicacionNom012 correo={correo_registrado} pass={pass_registrado} forma_de_pago={forma_de_pago} />
                )
              }
            </Spin>
          </Col>
        </Row>
      </Col></Row>
      <FooterIngenieriaVehicular />
      </>
  );
};

export default withRouter(Signup);
