import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';
import '../../assets/css/sala.css';

import expo1 from '../../assets/images/ponentes_06nov/antp_coloquio_BernardoMercado.png';
import expo2 from '../../assets/images/ponentes_06nov/antp_coloquio_CarlosGodinez.png';
import expo3 from '../../assets/images/ponentes_06nov/antp_coloquio_DidierColin.png';
import expo4 from '../../assets/images/ponentes_06nov/antp_coloquio_FranciscoKim.png';
import expo5 from '../../assets/images/ponentes_06nov/antp_coloquio_HumbertoVargas.png';
import expo6 from '../../assets/images/ponentes_06nov/antp_coloquio_JavierVera.png';

import { CloseOutlined } from '@ant-design/icons';

const expositor = [
  {nombre: 'Bernardo Mercado', tema:'', foto:expo1, boton: '', url:'', semblanza:[null]},
  {nombre: 'Carlos Godinez', tema:'', foto:expo2, boton: '', url:'', semblanza:[null]},
  {nombre: 'Didier Colín', tema:'', foto:expo3, boton: '', url:'', semblanza:[null]},
  {nombre: 'Francisco Kim', tema:'', foto:expo4, boton: '', url:'', semblanza:[null]},
  {nombre: 'Humberto Vargas', tema:'', foto:expo5, boton: '', url:'', semblanza:[null]},
  {nombre: 'Javier Vera', tema:'', foto:expo6, boton: '', url:'', semblanza:[null]},
  

]
const PonenteActual = (props) =>{
  const [ver_texto, setVertexto] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let visualizar_ponentes = expositor.map((expo, index)=>{
    return(
      <>
      {expo.semblanza != null ?
      <Col xs={10} md={5} onClick={()=>(setVertexto(index),setIsModalVisible(true))} style={{cursor:'pointer', margin:'10px'}} >
        <img src={expo.foto} style={{width:'100%', cursor:''}} />
        <p className='nombre_ponente_2'>{expo.nombre}</p>
        
      {/*<p className='link_ponente'>Ver semblanza</p>
      <div className="contenedor_imagen_con">
          <img src={expo.foto} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con">{expo.nombre}</p>
              <br />
              <p style={{display:'non'}}>
                {expo.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setVertexto(index);
                }}
                className="btn_desc_con"
              >
                Leer semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>*/}
    </Col>
    :
    <Col xs={10} md={4}>
        <img src={expo.foto} style={{width:'100%', cursor:''}} />
      </Col>}
      </>
    );
  });
  let ver_texto_modal = expositor.filter((item,index) => {
    if(index === ver_texto){
      return true;
    }else{
      return false;
    }
  }).map((texto,index)=>{
    return(
      <>
      <p className='nombre_ponente'>{texto.nombre}</p>
    <p className='texto_ponente'>{texto.semblanza}</p>
    </>
    )
  })

  return(
    <>
    <Row justify={'center'} style={{padding:'3% 0%'}}>
      <Col xs={{span:20}}>
        <p className='titulo_ponente'>PONENTES</p>
        <Row justify={'center'}>
      {visualizar_ponentes}

        </Row>
      </Col>
    </Row>
    <Modal
        open={isModalVisible}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
}
export default PonenteActual;