import React from 'react';
import { Row, Col, Carousel } from 'antd';
import {} from 'react-router-dom';
import '../../assets/css/evento.css';
import CarouselPatrocinadores from './CarouselPatrocinadores';
import Header from '../master/Header';
import Footer from '../master/Footer';
import EntradaGeneral from '../../assets/images/01_impacDemo_home/04-entrada-general.png';
import EntradaPlatinum from '../../assets/images/01_impacDemo_home/04-entrada-platinum.png';
import EntradaPremium from '../../assets/images/01_impacDemo_home/04-entrada-premium.png';

import ponente1 from '../../assets/images/01_impacDemo_home/02-ponente1.png';
import ponente2 from '../../assets/images/01_impacDemo_home/02-ponente2.png';

import img1 from '../../assets/images/01_impacDemo_home/03-minuatura-transmision.jpg';

import revivir from '../../assets/images/01_impacDemo_home/03 boton-entrar.png';
import entrar from '../../assets/images/01_impacDemo_home/03 boton-revivelo.png';
import imglive from '../../assets/images/01_impacDemo_home/03-icono-live.png';

function onChange(a, b) {
  console.log(a, b);
}

function onChange2(a, b) {
  console.log(a, b);
}

const progra = [
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:'', nombre:'Inauguración nombre del evento', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/', link:revivir},
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:'', nombre:'Primer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/', link:revivir},
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:imglive, nombre:'Segunda conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:img1, url:'/', link:entrar},
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:'', nombre:'Tercer conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:'', nombre:'Cuarta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},
  {hora:'00:00 - 00:00', fecha:'24 de julio 2020', live:'', nombre:'Quinta conferencia o panel', descripcion:'Breve descripción, locación o participantes de la actividad.', dia:'1', img:'', url:'', link:''},
];

const Evento = () =>{
  
  let visualizar_programa = progra.map((seccion)=>{
    return(
      <div className="info-horario">
        
        <Row justify="center" align="middle">
          <Col span={3}>
            <p style={{ textAlign:'left' }}>
              <img alt="" style={{ width:'50%' }} src={seccion.live}/><br/>
              <span className="info-hora">{seccion.hora}</span><br />
              <span className="info-fecha">{seccion.fecha}</span>
              </p>
          </Col>
          
          <Col span={11}>
            <p>
            <span className="info-nombre"><strong>{seccion.nombre}</strong></span><br />
            <span className="info-descripcion"><strong>{seccion.descripcion}</strong></span></p>
          </Col>
          <Col span={3}>
            <img alt="" style={{ width:'100%' }} className="info-video" src={seccion.img} />
          </Col>
          <Col span={3}>
            <a href={seccion.url}>
              <img alt="" style={{ width:'70%' }} src={seccion.link}/>
            </a>
          </Col>
          <Col span={20}>
            <hr style={{ color:'#000000' }} />
          </Col>
        </Row>
      </div>
    );
  });
  
  return(
    <>
    <Header/>
    <Row justify="center" align="middle">

      <Col span={24}>
        <Carousel afterChange={onChange}>
          <div className="principal" id="eventos">
            <p className="titulo">EVENTOS VIRTUALES,<br/>
            HÍBRIDOS Y PRESENCIALES<br/>
            <span className="fecha">24 de julio 2020</span></p>
            <Row justify="center">
              <Col span={3}>
                <p className="contador">
                  <span className="fecha">DÍAS</span><br />
                  <span className="numero">00</span>
                </p>
              </Col>
              <Col span={3}>
                <p className="contador">
                  <span className="fecha">HORAS</span><br />
                  <span className="numero">00</span>
                </p>
              </Col>
              <Col span={3}>
                <p className="contador">
                  <span className="fecha">MINUTOS</span><br />
                  <span className="numero">00</span>
                </p>
              </Col>
              <Col span={3}>
                <p className="contadorf">
                  <span className="fecha">SEGUNDOS</span><br />
                  <span className="numero">00</span>
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={16}>
                <p className="subtitle">Ejecuta eventos de cualquier tipo a la perfección, contamos<br/>
                con todo lo que necesitas para cada tipo de evento.</p>
              </Col>
            </Row>
          </div>
        </Carousel>
      </Col>
      <Col span={24}>
            <Carousel autoplay>
              <div>
                <img src={ponente1} className="ponente" />
              </div>
              <div>
                <img src={ponente1} className="ponente" />
              </div>
              
            </Carousel>
      </Col>
      <Col span={24} className="programa" id="programa">
        <Row justify="center" align="middle">
          <Col span={18} align="left">
            <p className="tituPrograma">PROGRAMA</p>
          </Col>
          <Col span={24} >
            <Row justify="center" align="middle">
              <Col span={18} className="divPrograma">
                {visualizar_programa}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <br/><br/><br />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="entradas">
        <Row justify="center" align="middle">
        <Col span={18} align="right">
          <p className="tituEntradas"><strong>ENTRADAS</strong></p>
          <p className="textEntradas"><strong>Al no contar con la limitación de la ubicación presencial, se puede convocar<br />a un público más amplio y sin límite de capacidad.</strong></p>
        </Col>
          <Col span={20} >
            <Row justify="center" align="middle">
              <Col span={12} className="divEntradas">
                <img alt="" style={{ width:'100%' }} src={EntradaGeneral} /><br/>
                <img alt="" style={{ width:'100%' }} src={EntradaPlatinum} /><br/>
                <img alt="" style={{ width:'100%' }} src={EntradaPremium} />
              </Col>
            </Row>
          </Col>
          <Col span={24} >
            <br/><br/>
          </Col>
          <Col span={20} >
            <p className="tituLogos">
              <strong>AGRADECEMOS A NUESTROS PATROCINADORES:</strong>
            </p>
          </Col>
          <Col span={20}>
          <CarouselPatrocinadores num_items={6} />
            <br/><br/>
          </Col>
        </Row>
      </Col>
    </Row>
    <Footer />
    </>
  );
}

export default Evento;