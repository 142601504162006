import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Spin,
  Upload,
  message
} from 'antd';
import { Element } from "react-scroll";

import axios from 'axios';
import Firebase from "../../utils/firebaseConfig";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import img_crear from '../../assets/images/eventos/energia_del_futuro/02 BotonRegistrate.png';
import RegistroCompletoPresencial from './RegistroCompletoPresencial';

import '../../assets/css/registro2.css';

import flujo from '../../assets/images/antp_Nom012_BannerContenido.png';
import img_logo_antp from '../../assets/images/ciclo-de-conferencias__banner_sinboton.jpg';
import img_logo_antp_movil from '../../assets/images/ciclo-de-conferencias_banner_movilsinboton.jpg';

import pdf_temario from '../../assets/documentos/temario_cicloconferencias.pdf';

import { mail_registro_confirmacion, SERVER_URL, uploadFile } from '../../utils/ConexionServidor';
import HeaderIngenieriaVehicular from "../master/header/HeaderIngenieriaVehicular";
import FooterIngenieriaVehicular from "../master/footer/FooterIngenieriaVehicular";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Ponentes from "../conferencias/Ponentes";

const nom_evento = '1-coloquio-transporte-multimodal';
const app = new Firebase();

let correo_registrado = ''; let pass_registrado = '';
const { Option } = Select;

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description:
      texto,
    duration: 10,
  });
};

const SeguridadPatrimonial = ({ setsignup, history }) => {
  const [error, seterror] = useState("");
  const [confirmacion, setConfirmacion] = useState(false);
  const [otro_medio, setOtroMedio] = useState(false);
  const [facturar, setFacturar] = useState(false);
  const [forma_de_pago, setFormaDePago] = useState('');
  const [cargando_registro, setCargandoRegistro] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_recaptcha = React.createRef();
  
  const elegirMedio = value => {


    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      openNotificationWithIcon('warning', 'Advertencia', 'Confirma no ser un robot');
      return;
    }

    let recaptcha = ref_recaptcha.current.getValue();

    setCargandoRegistro(true);

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };

    values.password = values.ap_paterno.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    const { correo, password } = values;

    let contra = values.password;

    correo_registrado = correo;
    pass_registrado = password;

    //delete values.password;
    delete values.aceptar_terminos;
    delete values.confirm;

    values.nombre_curso = nom_evento;

    if (values.medio_enterado === 'Otro') {
      values.medios = values.otro_medio;
      delete values.otro_medio;
    } else {
      values.medios = values.medio_enterado;
    }

    values.nombre1 = values.nombre;
    values.apellidos = values.ap_materno;
    values.pass = values.ap_paterno;
    values.procedencia = '';
    values.fecha_nacimiento1 = '';
    values['g-recaptcha-response'] = recaptcha;
    values.otro_procedencia = values.empresa;
    values.pass = contra;

    console.log(values);


    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          openNotificationWithIcon('success', '', data);
          /*app.escribir(nom_evento+'/usuarios',undefined,values, () => {
              //history.push('/conferencias');
            });*/
          setCargandoRegistro(false);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon('warning', '', data);
          //setConfirmacion(true);
          setCargandoRegistro(false);
          break;
      }
      setCargandoRegistro(false);
    });




  };

  return (<>
      <HeaderIngenieriaVehicular />
        
      <Row className="contenedor_registro_" style={{backgroundColor:'white'}}>
        <Col span={24}>
      
      <Row >
      <Col xs={{span:24}} lg={{span:0}} style={{backgroundColor:'white'}}>
        <Link to="#registro"><img src={img_logo_antp_movil} style={{width:'100%'}} /></Link>
        
        </Col>
            <Col xs={{span:0}} lg={{span:24}}>
            <Link to="#registro"><img src={img_logo_antp} style={{width:'100%'}} /></Link>
        
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:14, offset:5}}>
            <br />
            <p className="texto_registro_info" style={{textAlign:'justify'}}>
            <strong>Preámbulo:</strong>
            <br />
            El incremento de riesgos en la cadena de suministro en los últimos años ha provocado que las empresas generadoras de carga, empresas transportistas y de logística, implementen medidas y acciones para proteger su patrimonio de posibles amenazas o riesgos en la distribución de mercancías. 
            <br /><br />
            La seguridad patrimonial en la cadena de suministro busca la protección y vigilancia de personas, información, mercancías y sus traslados, con la finalidad de evitar pérdidas o daños que puedan afectar su rentabilidad y competitividad, así como generar confianza en sus clientes, proveedores, socios y empleados, mejorando con ello la productividad y competitividad de las empresas.
            <br /><br />
            <strong>Objetivo: </strong><br />
            Conocer las acciones que ayuden a establecer, implementar, mantener y mejorar un sistema de gestión de la seguridad en las áreas de seguridad patrimonial, para reducir y gestionar los riesgos y amenazas de seguridad en la cadena de suministro.
            <br /><br />
            <strong>Dirigido a:</strong> <br />Directivos de empresas generadoras de carga, empresas con flota propia, empresas transportistas y de logística; personal responsable de las áreas de operación, seguridad patrimonial; recursos humanos, así como a todo interesado en la prevención y respuesta a hechos delictivos.
            <br /><br />
            <ol type="1"> 
              <li>Incrementar la eficiencia en la cadena de suministro, así como la competitividad industrial.</li>
              <li>Protección de la imagen corporativa y establecer una cultura de prevención.</li>
              <li>Identificar, evaluar y mitigar los riesgos y amenazas que pueden afectar el patrimonio de las empresas.</li>
              <li>Competitividad y productividad para las empresas.</li>
              <li>Networking</li>
            </ol>
            </p>
            {/*¿Requieres más información? Contáctanos:<br />
            Daniel Montañez, <a href="tel:+525536421790" target="_blank">55 3642 1790</a>, <a href="mailto:d.montanez@antp.org.mx" target="_blank">d.montanez@antp.org.mx</a><br />
  Gustavo Chavez, <a href="tel:+525536419995" target="_blank">55 3641 9995</a>, <a href="mailto:g.chavez@antp.org.mx" target="_blank">g.chavez@antp.org.mx</a>*/}
            
            {/*<object data={pdf_temario} className="" type="application/pdf">
        <div>No online PDF viewer installed</div>
    </object>
            <iframe src={pdf_temario} frameborder="0" height="100%" width="100%">
</iframe>*/}
<br /><br />
<Link to={pdf_temario} style={{display:'non'}} target='_blank' className="temario">
  CONOCE EL TEMARIO DANDO CLICK AQUÍ</Link>

          </Col>
          <Col xs={{span:24}}>
            <Ponentes />
          </Col>

      </Row>
      </Col>
      </Row>
      <FooterIngenieriaVehicular />
      </>
  );
};

export default withRouter(SeguridadPatrimonial);
