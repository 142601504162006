import React, {useEffect, useState} from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Radio, notification} from 'antd';

import { get_token_resgistro, responder_encuesta } from '../../utils/ConexionServidor';

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}

export const Cuestionario = (props) => {

    const [token, setToken] = useState('');
    const [folio, setFolio] = useState('');
    const { nombre_curso, correo, onResponse } = props;


    const onFinish = values => {
        

        responder_encuesta(nombre_curso, token, folio, values).then(data => {
            if(data == 1){
                mensaje('success','Gracias por tu participación','');
                onResponse();
            }
        });

    };

      useEffect(() => {
        
        const data = {
            correo: correo,
            nombre_evento: nombre_curso
        };

        get_token_resgistro(data).then(datos => {
            

            if(datos[0] === 1){
                onResponse();
            }else{
                setToken(datos[1]);
                setFolio(datos[2]);
            }
        });

      },[]);

      return (
          <Row>
              <Col span={20} offset={2}>
              
                <p style={{textAlign:'center'}}>Te pedimos responder la siguiente encuesta de satisfacción que nos ayudará a mejorar nuestros servicios.</p>
                <br />
              </Col>
              <Col span={22} offset={1}>
              <Form
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
          <Row>
              <Col span={24}>
             
              <p style={{textAlign:'justify', fontWeight:'bold'}}>1. La información previa sobre horarios, plataforma, objetivos del seminario fue clara y oportuna</p>
                <Form.Item name="pregunta1" label="" 
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />2. La plataforma zoom que se utilizó fue la adecuada (fácil de utilizar, fácil acceso)</p>
                <Form.Item name="pregunta2" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />3. Los ponentes presentaron dominio del tema, argumentando con evidencia y respondiendo preguntas complejas</p>
                <Form.Item name="pregunta3" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />4. El seminario estimula la participación, generando un ambiente cálido y motivante</p>
                <Form.Item name="pregunta4" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />5. Los objetivos de aprendizaje fueron claros, desafiantes y alcanzables</p>
                <Form.Item name="pregunta5" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />6. La metodología utilizada en el seminario cumplió con el objetivo</p>
                <Form.Item name="pregunta6" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />7. La duración del seminario fue adecuada a los objetivos de aprendizaje.</p>
                <Form.Item name="pregunta7" label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} >
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />8. Participar en este seminario me ha aportado conocimientos relevantes para desempeñar mi trabajo</p>
                <Form.Item name="pregunta8" 
                label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Radio.Group>
                        <Radio value="En total desacuerdo">En total desacuerdo</Radio>
                        <Radio value="En desacuerdo">En desacuerdo</Radio>
                        <Radio value="Neutral">Neutral</Radio>
                        <Radio value="De acuerdo">De acuerdo</Radio>
                        <Radio value="En total acuerdo">En total acuerdo</Radio>
                    </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24} >
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />9. Temas del sector en los que te gustaría que impartiéramos otro seminario</p>
                <Form.Item name="pregunta9" 
                label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Input />
                </Form.Item>
              </Col>

              <Col span={24} >
              <p style={{textAlign:'justify', fontWeight:'bold'}}><br />10. ¿Hay algo que podría mejorar la impartición del seminario? Por favor, déjanos saber tu opinión</p>
                <Form.Item name="pregunta10" 
                label=""
                rules={[{ required: true, message: 'Contesta ésta pregunta' }]}>
                    <Input />
                </Form.Item>
              </Col>

          </Row>
        
  
        <Form.Item>
          <Col span={8} offset={8} style={{textAlign:'center'}}>
            <br />
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
          </Col>
          
        </Form.Item>
      </Form>
              </Col>
          </Row>
        
      );

}
