import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification
} from 'antd';
import  Firebase  from "../../utils/firebaseConfig";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import img_crear from '../../assets/images/02_registro/antp_reformaspenales_botonregistrate.png';
import  RegistroCompletoSinPago  from './RegistroCompletoSinPago';

import '../../assets/css/registro2.css';

import img_logo_antp from '../../assets/images/01_impacDemo_home/antp_reformaspenales_registro.jpg';
import img_logo_antp_movil from '../../assets/images/01_impacDemo_home/antp_ReformasPenales_ponentesregistroWEB.jpg';

import { mail_registro_confirmacion } from '../../utils/ConexionServidor';

const nom_evento = 'antp-reformas-penales';
const app_reformas = new Firebase();

let correo_registrado = '';
const { Option } = Select;

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description:
      texto,
  });
};

const Signup = ({ setsignup, history }) => {
    const [error, seterror] = useState("");
    const [confirmacion, setConfirmacion] = useState(false);
    const [otro_medio, setOtroMedio] = useState(false);

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();

    const elegirMedio = value => {
     

      if(value === 'Otro'){
        setOtroMedio(true);
      }else{
        setOtroMedio(false);
      }

    }

    const onFinish = async values => {
        
      if(ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' '){
        openNotificationWithIcon('warning','Advertencia','Confirma no ser un robot');
        return;
      }

      let recaptcha = ref_recaptcha.current.getValue();
      

        const { correo, password } = values;

        let contra = values.password;

        correo_registrado = correo;

        await app_reformas.app
        .auth()
        .createUserWithEmailAndPassword(correo, password)
        .then(result => {
          delete values.password;
          delete values.aceptar_terminos;
          delete values.confirm;
          
          app_reformas.escribir(nom_evento+'/usuarios',undefined,values, () => {
           
            
            values.nombre_curso = nom_evento;
              
              if(values.medio_enterado === 'Otro'){
                values.medios = values.otro_medio;
                delete values.otro_medio;
              }else{
                values.medios = values.medio_enterado;
              }

              values.nombre1 = values.nombre;
              values.apellidos = values.ap_paterno+' '+values.ap_materno;
              values.telefono = '';
              values.procedencia = '';
              values.fecha_nacimiento1 = '';
              values['g-recaptcha-response'] = recaptcha;
              values.otro_procedencia = values.empresa;
              values.pass = contra;

              mail_registro_confirmacion(values).then(({data,status}) => {
                switch(status){
                  case 0:
                    openNotificationWithIcon('success','',data);
                    setConfirmacion(true);
                  break;
                  default:
                    openNotificationWithIcon('warning','',data);
                    setConfirmacion(true);
                    break;
                }
                
              });

              history.push('/conferencias');

          });

          
        })
        .catch(error => {

          console.log('error');

            if(error.message === 'The email address is already in use by another account.'){
              openNotificationWithIcon('warning','Advertencia','Éste correo electrónico ya ha sido utilizado para otra cuenta');
            }else{
              openNotificationWithIcon('danger','Error',error.message);
            }
            
        });

      };
    return (
      <Row className="contenedor_registro">
        <Col span={24}>
      
      <Row >
      <Col xs={{span:24}} md={{span:0}}><br />
<           img src={img_logo_antp} style={{width:'100%'}} />
            <br /><br />
            </Col>
            <Col xs={{span:0}} md={{span:24}}><br />
            <img src={img_logo_antp_movil} style={{width:'100%'}} />
            <br /><br />
            </Col>
      </Row>
      <Row>
        <Col xs={{span:20, offset:2}} md={{span:14, offset:5}}>
        {!confirmacion ?
        (
        <Form className="login-form"
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <p style={{ textAlign:'left', fontSize:'22px', fontWeight:'bold', color:'#2776cc' }}>
              <br />
                            Registro
                            <hr />
                        </p>
            </Col>
            <Col span={24}>
            <Form.Item
        name="nombre"
        label={<span className="estilo_campos">Nombre</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu nombre!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
        name="ap_paterno"
        label={<span className="estilo_campos">Apellido paterno</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido paterno!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="ap_materno"
        label={<span className="estilo_campos">Apellido materno</span>}
        initialValues=''
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido materno!',
          },
          
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="correo"
        label={<span className="estilo_campos">Correo electrónico</span>}
        rules={[
          {
            type: 'email',
            message: '...',
          },
          {
            required: true,
            message: 'Ingresa tu correo electrónico !',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="password"
        label={<span className="estilo_campos">Elige una contraseña (Mínimo 8 caracteres)</span>}
        rules={[
          {
            required: true,
            message: 'Ingresa una contraseña!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="confirm"
        label={<span className="estilo_campos">Confirmar contraseña</span>}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Confirma tu contraseña!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('Asegúrate que las contraseñas coincidan!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="empresa"
        label={<span className="estilo_campos">Empresa</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa el nombre de tu empresa!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="medio_enterado"
        label={<span className="estilo_campos">¿Por qué medio te enteraste?</span>}
        rules={[
          {
            required: true,
            message: 'Elige el medio por el que te enteraste!',
          },
        ]}
      >
        <Select onChange={ elegirMedio } >
          <Option value="Facebook">Facebook</Option>
          <Option value="Twitter">Twitter</Option>
          <Option value="Instagram">Instagram</Option>
          <Option value="Correo electrónico">Correo electrónico</Option>
          <Option value="Whatsapp">Whatsapp</Option>
          <Option value="Otro">Otro</Option>
        </Select>
      </Form.Item>
            </Col>
            <Col span={24}>
            {otro_medio && 
      (
        <Form.Item
        name="otro_medio"
        label={<span className="estilo_campos">¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa el medio por el que te enteraste!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
            </Col>
            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('Acepta el aviso de privacidad'),
          },
        ]}
      >
        <Checkbox style={{color:'black'}}>
          Acepto el <span onClick={ () => { window.open('https://www.antp.org.mx/aviso-de-privacidad','Aviso de privacidad',"top=100,left=500,width=600,height=600");}}>
                      <strong>aviso de privacidad</strong>
                    </span> 
        </Checkbox>
      </Form.Item>
            </Col>
            <Col span={24}>
            <ReCAPTCHA ref={ref_recaptcha}
    sitekey="6LdbzWkUAAAAAJ0HKwuFITRLb25LQDsL50wkYwO_"
  />
            </Col>
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'150px', padding:'0px' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit()}} />
                
                <Button onClick={() => setsignup(false)} type="link" style={{display:'none'}}>
                    Ingresa ahora!
                </Button>
                </Form.Item>
            </Col>
          </Row>
        </Form>
        )
        :
        (
          <RegistroCompletoSinPago correo={correo_registrado} />
        )
        }
        </Col>
        </Row>
      </Col></Row>
    );
};

export default withRouter(Signup);
