import React, { useEffect, useContext ,useState} from "react";

import { Button, Menu, Row, Col } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';

const app = new Firebase('antp_ingenieria_vehicular');

const webinars = [
    {nombre: 'Demo', url:'/demo', key:'demo'}
];


const Perfil=({history}) =>{

    const { usuario, datos } = useContext(Auth);

    useEffect(() => {
        
        if (usuario===null) {
            history.push("/login");
        }
        
    }, [history, usuario]);

    const visualizar_webinars = webinars.map(webinar => {
        return(
        <Menu.Item key={webinar.key} url={webinar.url}>{webinar.nombre}</Menu.Item>
        );
    });

    const handleClick = e => {
        history.push(e.item.props.url);
      };

    
        return (

            <Row>
                <Col span={8}>
                    <Menu
                    onClick={handleClick}
                    style={{ width: 256 }}
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    >
                    
                        <Menu.ItemGroup key="webinars" title="Webinars">
{ visualizar_webinars }
                        </Menu.ItemGroup>
          
                    </Menu>
                </Col>
                <Col span={16}>
                <div
                        style={{
                            background: "#fff",
                            padding: 24,
                            minHeight: "80vh"
                        }}
                    >
                        Bienvenido <br /> <strong>{`${datos.nombre} ${datos.ap_paterno} ${datos.ap_materno}`}</strong>
                    </div>

                    <Button onClick={() => app.app.auth().signOut()} key="logout" type="primary">Cerrar Sesión</Button>
                </Col>
            </Row>

           
        );
    
}
export default withRouter(Perfil);
