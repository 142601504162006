export const cuestionario = [
    {
      "num_pregunta" : 1,
      "pregunta" : "¿Cuál es la designación correcta para la NOM de peso y dimensiones vigente?",
      "respuestas" :[
        {
          "respuesta" : "NOM-012-SCT-2-2017"
        },
        {
          "respuesta" : "NOM-012-SCT-2-2018"
        },
        {
          "respuesta" : "NOM-012-SCT-2-2008"
        },
        {
          "respuesta" : "NOM-012-SE-2-2017"
        }
      ]
    },
    {
      "num_pregunta" : 2,
      "pregunta" : "¿Qué objeto tiene la NOM-012?",
      "respuestas" :[{
          "respuesta" : "Establecer especificaciones de peso"
        },
        {
          "respuesta" : "Establecer especificaciones de dimensiones"
        },
        {
          "respuesta" : "Establecer especificaciones de peso, dimensiones y capacidad"
        }
      ]
    },
    {
      "num_pregunta" : 3,
      "pregunta" : "¿Cuál es el campo de aplicación de la NOM-012?",
      "respuestas" :[{
          "respuesta" : "A todos los vehículos que transitan por carreteras y puentes federales"
        },
        {
          "respuesta" : "Al autotransporte federal, sus servicios auxiliares y transporte privado que transitan en las vías generales de comunicación de jurisdicción federal"
        },
        {
          "respuesta" : "A vehículos tipo grúa de arrastre y arrastre y salvamento"
        }
      ]
    },
    {
      "num_pregunta" : 4,
      "pregunta" : "¿Cómo obtenemos el Peso Bruto Vehicular?",
      "respuestas" :[{
          "respuesta" : "Concentración de peso en los ejes"
        },
        {
          "respuesta" : "El peso del vehículo unitario"
        },
        {
          "respuesta" : "Suma del peso vehicular y el peso de la carga"
        }
      ]
    },
    {
      "num_pregunta" : 5,
      "pregunta" : "¿En qué casos se puede utilizar un semirremolque de tres ejes en un vehículo tractocamión semirremolque remolque?",
      "respuestas" : [
        {
          "respuesta" : "Nunca"
        },
        {
          "respuesta" : "Siempre que cumplas con el peso máximo autorizado y en los que uno de los ejes no tenga ninguna llanta"
        },
        {
          "respuesta" : "Siempre que cumplas con la dimensión máxima autorizada"
        }
      ]
    },
    {
      "num_pregunta" : 6,
      "pregunta" : "¿Cuáles son los tipos de camino que refiere la NOM-012?",
      "respuestas" :[{
          "respuesta" : "ET, K, C y D"
        },
        {
          "respuesta" : "ET, A, B, C y D"
        },
        {
          "respuesta" : "ET, A, E, F y D"
        }
      ]
    },
    {
      "num_pregunta" : 7,
      "pregunta" : "¿El Peso Bruto Vehicular máximo autorizado se puede incrementar 1.5 ton por cada eje motriz y 1.0 ton por cada eje de carga en todos los camiones unitarios y configuraciones vehiculares?",
      "respuestas" :[{
          "respuesta" : "Cierto"
        },
        {
          "respuesta" : "Falso"
        }
      ]
    },
    {
      "num_pregunta" : 8,
      "pregunta" : "¿Cuáles son las condiciones técnicas que debe cumplir un tractocamión en configuración semirremolque remolque (T-S-R) o semirremolque semirremolque (T-S-S)?",
      "respuestas" :[{
          "respuesta" : "Dictamen psicofísico, motor mecánico, torque mínimo, capacidad de los ejes de tracción, freno auxiliar de motor, convertidor con doble cadena de seguridad, sistema antibloqueo para frenos, suspensión de muelles, cámara de frenado de doble acción, regulador de la velocidad y cintas reflejantes"
        },
        {
          "respuesta" : "Dictamen de condiciones físico-mecánicas y de bajas emisiones, motor electrónico, torque mínimo, capacidad de los ejes de tracción, freno auxiliar de motor, convertidor con doble cadena de seguridad, sistema antibloqueo para frenos, suspensión de aire, cámara de frenado de doble acción, regulador de la velocidad y cintas reflejantes."
        },
        {
          "respuesta" : "Dictamen bajas emisiones, motor electrónico, torque mínimo, capacidad de los ejes de tracción, freno auxiliar de motor, convertidor con una cadena de seguridad, sistema antibloqueo para frenos, suspensión de aire, cámara de frenado acción, GPS y cintas reflejantes"
        }
      ]
    },
    {
      "num_pregunta" : 9,
      "pregunta" : "Un tractocamión en configuración semirremolque remolque (T-S-R) o semirremolque semirremolque (T-S-S) ¿Qué disposiciones deben cumplir adicionalmente?",
      "respuestas" :[{
          "respuesta" : "De tránsito, del conductor y de control para las empresas"
        },
        {
          "respuesta" : "De carga y descarga"
        },
        {
          "respuesta" : "De eficiencia y control de emisiones"
        }
      ]
    },
    {
      "num_pregunta" : 10,
      "pregunta" : "¿Cuáles son los componentes adicionales deben cumplir las configuraciones semirremolque remolque (T-S-R) o semirremolque semirremolque (T-S-S)?",
      "respuestas" :[{
          "respuesta" : "Salvaguardas"
        },
        {
          "respuesta" : "Espejos auxiliares, GPS y sistema de ajuste automático de freno"
        },
        {
          "respuesta" : "De carga y descarga"
        }
      ]
    },
    {
      "num_pregunta" : 11,
      "pregunta" : "¿El largo máximo autorizado por tipo de vehículo o en configuración y tipo de camino se establece en la tabla C de la NOM-012?",
      "respuestas" :[{
          "respuesta" : "Verdadero"
        },
        {
          "respuesta" : "Falso"
        }
      ]
    },
    {
      "num_pregunta" : 12,
      "pregunta" : "¿Qué disposición deben cumplir los semirremolques mayores a 48 pies en configuraciones T-S?",
      "respuestas" :[{
          "respuesta" : "Llevar torreta en la parte superior"
        },
        {
          "respuesta" : "Llevar salvaguardas"
        },
        {
          "respuesta" : "Llevar letrero fijo de PRECAUCIÓN AL REBASAR"
        },
        {
          "respuesta" : "Todas las anteriores"
        }
      ]
    },
    {
      "num_pregunta" : 13,
      "pregunta" : "Para el caso de conectividad para entrar o salir de una planta productora o centro logístico y/o de transferencia ¿Cuál es la longitud máxima que se puede solicitar para un tramo de menor clasificación?",
      "respuestas" :[{
          "respuesta" : "Hasta 50 km."
        },
        {
          "respuesta" : "Hasta 30 km."
        },
        {
          "respuesta" : "No se especifica"
        }
      ]
    },
    {
      "num_pregunta" : 14,
      "pregunta" : "Para el caso de conectividad cuando no estén conectados dos ejes o tramos de un mismo eje ¿Cuál es la longitud máxima que se puede solicitar para un tramo de menor clasificación tipo B?",
      "respuestas" :[{
          "respuesta" : "Cuando la longitud recorrida en cada dirección no sea mayor a 50 km"
        },
        {
          "respuesta" : "Cuando la longitud recorrida en cada dirección no sea mayor a 70 km"
        },
        {
          "respuesta" : "Cuando la longitud recorrida en cada dirección no sea mayor a 100 km"
        }
      ]
    },
    {
      "num_pregunta" : 15,
      "pregunta" : "¿Cuál es la vigencia de la autorización de conectividad?",
      "respuestas" :[{
          "respuesta" : "5 años"
        },
        {
          "respuesta" : "2 años"
        },
        {
          "respuesta" : "3 años"
        }
      ]
    },
    {
      "num_pregunta" : 16,
      "pregunta" : "La Secretaría resolverá en un plazo no mayor de sesenta días naturales, contados a partir de la recepción de la solicitud. En caso de que no la emita en el plazo señalado, se entenderá en sentido negativo.",
      "respuestas" :[{
          "respuesta" : "Verdadero"
        },
        {
          "respuesta" : "Falso"
        }
      ]
    }
  ]
