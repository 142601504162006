import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Spin,
  Upload,
  message
} from 'antd';
import { Element } from "react-scroll";

import axios from 'axios';
import Firebase from "../../utils/firebaseConfig";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import img_crear from '../../assets/images/eventos/energia_del_futuro/02 BotonRegistrate.png';
import RegistroCompletoPresencial from './RegistroCompletoPresencial';

import '../../assets/css/registro2.css';

import flujo from '../../assets/images/antp_Nom012_BannerContenido.png';
import img_logo_antp from '../../assets/images/coloquio_BannerWeb_4.jpg';
import img_logo_antp_movil from '../../assets/images/coloquio_BannerWeb_movil_4.jpg';

import pdf_temario from '../../assets/documentos/temario_cicloconferencias.pdf';

import { mail_registro_confirmacion, SERVER_URL, uploadFile } from '../../utils/ConexionServidor';
import HeaderIngenieriaVehicular from "../master/header/HeaderIngenieriaVehicular";
import FooterIngenieriaVehicular from "../master/footer/FooterIngenieriaVehicular";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Ponentes from "../conferencias/Ponentes";

const nom_evento = '1-coloquio-transporte-multimodal';
const app = new Firebase();

let correo_registrado = ''; let pass_registrado = '';
const { Option } = Select;

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description:
      texto,
    duration: 10,
  });
};

const Signup = ({ setsignup, history }) => {
  const [error, seterror] = useState("");
  const [confirmacion, setConfirmacion] = useState(false);
  const [otro_medio, setOtroMedio] = useState(false);
  const [facturar, setFacturar] = useState(false);
  const [forma_de_pago, setFormaDePago] = useState('');
  const [cargando_registro, setCargandoRegistro] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_recaptcha = React.createRef();
  
  let url_file = "";

  let normFile = (e) => {

    //url_file = e.file.response.data;

    if (e.file.response !== undefined) {
      console.log('Upload event:', e.file.response.data);
      url_file = e.file.response.data;
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  
  
  const elegirMedio = value => {


    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      openNotificationWithIcon('warning', 'Advertencia', 'Confirma no ser un robot');
      return;
    }

    let recaptcha = ref_recaptcha.current.getValue();

    setCargandoRegistro(true);

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };

    values.password = values.ap_paterno.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    const { correo, password } = values;

    let contra = values.password;

    correo_registrado = correo;
    pass_registrado = password;

    //delete values.password;
    delete values.aceptar_terminos;
    delete values.confirm;

    values.nombre_curso = nom_evento;

    if (values.medio_enterado === 'Otro') {
      values.medios = values.otro_medio;
      delete values.otro_medio;
    } else {
      values.medios = values.medio_enterado;
    }

    values.nombre1 = values.nombre;
    values.apellidos = values.ap_materno;
    values.pass = values.ap_paterno;
    values.procedencia = '';
    values.fecha_nacimiento1 = '';
    values['g-recaptcha-response'] = recaptcha;
    values.otro_procedencia = values.empresa;
    values.pass = contra;


    values['file'] = url_file;

    console.log(values);


    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          openNotificationWithIcon('success', '', data);
          /*app.escribir(nom_evento+'/usuarios',undefined,values, () => {
              //history.push('/conferencias');
            });*/
          setCargandoRegistro(false);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon('warning', '', data);
          //setConfirmacion(true);
          setCargandoRegistro(false);
          break;
      }
      setCargandoRegistro(false);
    });




  };

  return (<>
      <HeaderIngenieriaVehicular />
        
      <Row className="contenedor_registro_" style={{backgroundColor:'white'}}>
        <Col span={24}>
      
      <Row >
      <Col xs={{span:24}} lg={{span:0}} style={{backgroundColor:'white'}}>
        <Link to="#registro"><img src={img_logo_antp_movil} style={{width:'100%'}} /></Link>
        
        </Col>
            <Col xs={{span:0}} lg={{span:24}}>
            <Link to="#registro"><img src={img_logo_antp} style={{width:'100%'}} /></Link>
        
            </Col>
            <Col xs={{span:20, offset:2}} md={{span:14, offset:5}}>
            <br />
            <p className="texto_registro_info" style={{textAlign:'justify'}}>
            <strong>Preámbulo:</strong>
            <br />
            El incremento de riesgos en la cadena de suministro en los últimos años ha provocado que las empresas generadoras de carga, empresas transportistas y de logística, implementen medidas y acciones para proteger su patrimonio de posibles amenazas o riesgos en la distribución de mercancías. 
            <br /><br />
            La seguridad patrimonial en la cadena de suministro busca la protección y vigilancia de personas, información, mercancías y sus traslados, con la finalidad de evitar pérdidas o daños que puedan afectar su rentabilidad y competitividad, así como generar confianza en sus clientes, proveedores, socios y empleados, mejorando con ello la productividad y competitividad de las empresas.
            <br /><br />
            <strong>Objetivo: </strong><br />
            Conocer las acciones que ayuden a establecer, implementar, mantener y mejorar un sistema de gestión de la seguridad en las áreas de seguridad patrimonial, para reducir y gestionar los riesgos y amenazas de seguridad en la cadena de suministro.
            <br /><br />
            <strong>Dirigido a:</strong> <br />Directivos de empresas generadoras de carga, empresas con flota propia, empresas transportistas y de logística; personal responsable de las áreas de operación, seguridad patrimonial; recursos humanos, así como a todo interesado en la prevención y respuesta a hechos delictivos.
            <br /><br />
            <ol type="1"> 
              <li>Incrementar la eficiencia en la cadena de suministro, así como la competitividad industrial.</li>
              <li>Protección de la imagen corporativa y establecer una cultura de prevención.</li>
              <li>Identificar, evaluar y mitigar los riesgos y amenazas que pueden afectar el patrimonio de las empresas.</li>
              <li>Competitividad y productividad para las empresas.</li>
              <li>Networking</li>
            </ol>
            </p>
            {/*¿Requieres más información? Contáctanos:<br />
            Daniel Montañez, <a href="tel:+525536421790" target="_blank">55 3642 1790</a>, <a href="mailto:d.montanez@antp.org.mx" target="_blank">d.montanez@antp.org.mx</a><br />
  Gustavo Chavez, <a href="tel:+525536419995" target="_blank">55 3641 9995</a>, <a href="mailto:g.chavez@antp.org.mx" target="_blank">g.chavez@antp.org.mx</a>*/}
            
            {/*<object data={pdf_temario} className="" type="application/pdf">
        <div>No online PDF viewer installed</div>
    </object>
            <iframe src={pdf_temario} frameborder="0" height="100%" width="100%">
</iframe>*/}
<br /><br />
<Link to={pdf_temario} target='_blank' className="temario">
  CONOCE EL TEMARIO DANDO CLICK AQUÍ</Link>

          </Col>
          <Col xs={{span:24}}>
            <Ponentes />
          </Col>

      </Row>
      <Row>
      
        <Col xs={{span:20, offset:2}} md={{span:14, offset:5}}>
        <Spin spinning={cargando_registro}>
        {!confirmacion ?
        (
        <Form className="login-form"
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        /*initialValues={{
          remember: true,
          nombre: 'Martin',
          ap_paterno: 'Rodríguez',
          ap_materno: 'Ayala',
          correo:'martin@thethree.com.mx',
          empresa: 'The Three',
          medio_enterado: 'Facebook',
          razon_social: 'MARTIN',
          rfc:'M4T1N',
        cp:'00000',
        correo_factura:'martin@facturas.com',
        uso_cdfi: 'Por definir (P01)'


        }}*/
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <Element name="#registro"></Element>
            <p id="registro" style={{ textAlign:'left', fontSize:'22px', fontWeight:'bold', color:'#112f6d' }}>
              <br />
                            REGÍSTRATE
                            <hr style={{backgroundColor:'#7ce6e0', border:'1px solid #7ce6e0'}} />
                        </p>
            </Col>
            <Col span={24}>
            <Form.Item
        name="nombre"
        label={<span className="estilo_campos">Nombre</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu nombre!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
        name="ap_paterno"
        label={<span className="estilo_campos">Apellido paterno</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido paterno!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="ap_materno"
        label={<span className="estilo_campos">Apellido materno</span>}
        initialValues=''
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido materno!',
          },
          
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
        name="correo"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: '...',
          },
          {
            required: true,
            message: 'Ingresa tu correo electrónico !',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="telefono"
        label="Teléfono celular"
        rules={[
          {
            required: true,
            message: 'Ingresa tu número de celular!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
      
            <Col span={24}>
            <Form.Item
        name="empresa"
        label={<span className="estilo_campos">Empresa</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa el nombre de tu empresa!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="medio_enterado"
        label={<span className="estilo_campos">¿Por qué medio te enteraste?</span>}
        rules={[
          {
            required: true,
            message: 'Elige el medio por el que te enteraste!',
          },
        ]}
      >
        <Select onChange={ elegirMedio } >
          <Option value="Facebook">Facebook</Option>
          <Option value="Twitter">Twitter</Option>
          <Option value="Instagram">Instagram</Option>
          <Option value="Correo electrónico">Correo electrónico</Option>
          <Option value="Whatsapp">Whatsapp</Option>
          <Option value="Otro">Otro</Option>
        </Select>
      </Form.Item>
            </Col>
            <Col span={24}>
            {otro_medio && 
      (
        <Form.Item
        name="otro_medio"
        label={<span className="estilo_campos">¿Cuál?</span>}
        rules={[
          
          {
            required: true,
            message: 'Ingresa el medio por el que te enteraste!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
            </Col>
            <Col span={24}>
            <Form.Item
        name="forma_pago"
        label={<span className="estilo_campos">Forma de pago</span>}
        rules={[
          {
            required: true,
            message: 'Elige una forma de pago!',
          },
        ]}
      >
        <Select onChange={ (e)=> setFormaDePago(e) } >
          <Option value="Efectivo o depósito bancario (01)">Efectivo o depósito bancario (01)</Option>
          <Option value="Cheque nominativo (02)">Cheque nominativo (02)</Option>
          <Option value="Transferencia electrónica de fondos (03)">Transferencia electrónica de fondos (03)</Option>
          <Option value="Tarjeta de crédito (04)">Tarjeta de crédito (04)</Option>
          <Option value="Tarjeta de débito (28)">Tarjeta de débito (28)</Option>
          <Option value="Por definir (99)">Por definir (99)</Option>
        </Select>
      </Form.Item>
            </Col>

        {(forma_de_pago === 'Tarjeta de crédito (04)' || forma_de_pago === 'Tarjeta de débito (28)') &&
            <>
                <Col span={11}>
            <Form.Item
                name="tipo_tarjeta"
                label={<span className="estilo_campos">Tipo tarjeta</span>}
                rules={[
                {
                    required: false,
                    message: 'Selecciona un tipo de tarjeta!',
                },
                ]}
            >
        <Select onChange={ elegirMedio } >
          <Option value="Mastercard">Mastercard</Option>
          <Option value="Visa">Visa</Option>
        </Select>
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
                <Row>
                    <Col span={24}>
                    <p className="estilo_campos" style={{marginBottom:'5px', textAlign:'left'}}>Número tarjeta</p>
                    
                    </Col>
                    <Col span={6}>
                    <Form.Item
                        name="tarjeta14"
                        label={null}
                        initialValues=''
                        rules={[
                        {
                            required: false,
                            message: '',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                        name="tarjeta58"
                        label={null}
                        initialValues=''
                        rules={[
                        {
                            required: false,
                            message: '',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                        name="tarjeta912"
                        label={null}
                        initialValues=''
                        rules={[
                        {
                            required: false,
                            message: '',
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                        name="tarjeta1316"
                        label={null}
                        initialValues=''
                        rules={[
                        {
                            required: false,
                            message: '',
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    </Col>
                </Row>
            </Col>
            <Col span={11}>
            <Form.Item
                name="mes_vencimiento"
                label={<span className="estilo_campos">Mes vencimiento</span>}
                rules={[
                {
                    required: false,
                    message: 'Selecciona un mes!',
                },
                ]}
            >
        <Select onChange={ elegirMedio } >
          <Option value="Enero">Enero</Option>
          <Option value="Febrero">Febrero</Option>
          <Option value="Marzo">Marzo</Option>
          <Option value="Abril">Abril</Option>
          <Option value="Mayo">Mayo</Option>
          <Option value="Junio">Junio</Option>
          <Option value="Julio">Julio</Option>
          <Option value="Agosto">Agosto</Option>
          <Option value="Septiembre">Septiembre</Option>
          <Option value="Octubre">Octubre</Option>
          <Option value="Noviembre">Noviembre</Option>
          <Option value="Diciembre">Diciembre</Option>
        </Select>
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
                name="anio_vencimiento"
                label={<span className="estilo_campos">Año vencimiento</span>}
                rules={[
                {
                    required: false,
                    message: 'Selecciona un año!',
                },
                ]}
            >
        <Select onChange={ elegirMedio } >
          <Option value="2021">2021</Option>
          <Option value="2022">2022</Option>
          <Option value="2023">2023</Option>
          <Option value="2024">2024</Option>
          <Option value="2025">2025</Option>
          <Option value="2026">2026</Option>
          <Option value="2027">2027</Option>
          <Option value="2028">2028</Option>
          <Option value="2029">2029</Option>
          <Option value="2030">2030</Option>
          <Option value="2031">2031</Option>

        </Select>
      </Form.Item>
            </Col>
            </>
        }


            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item
        name="factura"
        valuePropName="checked"
      >
        <Checkbox style={{color:'black'}} onChange={()=>setFacturar(!facturar)}>
          ¿Deseo facturar?
        </Checkbox>
      </Form.Item>
            </Col>

{facturar &&
    <>
     <Col span={24}>
                            <Form.Item
                              name="file"
                              label="Constancia de situación fiscal"
                              rules={[
                                {
                                  required: true,
                                  message:"Selecciona un archivo",
                                },
                              ]}
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                            >
                              <Upload name="file" action={`${SERVER_URL}/webinar/archivo-acreditar`} listType="picture">
                                <Button style={{ width: '200px' }}>Subir archivo</Button>
                              </Upload>
                            </Form.Item>
                          </Col>
        <Col span={11}>
            <Form.Item
                name="razon_social"
                label={<span className="estilo_campos">Razón social</span>}
                rules={[
                {
                    required: true,
                    message: 'Ingresa una razón social!',
                },
                ]}
            >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="rfc"
        label={<span className="estilo_campos">RFC</span>}
        initialValues=''
        rules={[
          {
            required: true,
            message: 'Ingresa un RFC!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
                name="cp"
                label={<span className="estilo_campos">Código postal</span>}
                rules={[
                {
                    required: true,
                    message: 'Ingresa un código postal!',
                },
                ]}
            >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="correo_factura"
        label={<span className="estilo_campos">Correo para facturar</span>}
        initialValues=''
        rules={[
          {
            required: true,
            message: 'Ingresa un correo electrónico!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="uso_cdfi"
        label={<span className="estilo_campos">Uso de CFDI</span>}
        rules={[
          {
            required: true,
            message: 'Elige cfdi',
          },
        ]}
      >
        <Select onChange={ elegirMedio } >
          <Option value="Gastos en general (G03)">Gastos en general (G03)</Option>
          <Option value="Donativos (D04)">Donativos (D04)</Option>
          <Option value="Pagos por servicios educativos (colegiaturas) (D10)">Pagos por servicios educativos (colegiaturas) (D10)</Option>
          <Option value="Por definir (P01)">Por definir (01)</Option>
        </Select>
      </Form.Item>
            </Col>
    </>
}
            

            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('Acepta el aviso de privacidad'),
          },
        ]}
      >
        <Checkbox style={{color:'black'}}>
          Acepto el <span onClick={ () => { window.open('https://www.antp.org.mx/aviso-de-privacidad','Aviso de privacidad',"top=100,left=500,width=600,height=600");}}>
                      <strong>aviso de privacidad</strong>
                    </span> 
        </Checkbox>
      </Form.Item>
            </Col>
            <Col span={24}>
            <ReCAPTCHA ref={ref_recaptcha}
    sitekey="6LdbzWkUAAAAAJ0HKwuFITRLb25LQDsL50wkYwO_"
  />
            </Col>
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'150px', padding:'0px' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit()}} />
                
                <Button onClick={() => setsignup(false)} type="link" style={{display:'none'}}>
                    Ingresa ahora!
                </Button>
                <br /><br /><br /><br />
                </Form.Item>
            </Col>
            <Col span={0}>
                <p style={{backgroundColor:'#8ec549', color:'#112f6d', textAlign:'center', fontSize:'16px', padding:'10px'}}><strong>Importante: </strong> Debes estar registrado para poder enviar preguntas</p>
                <br /><br /><br />
            </Col>
          </Row>
        </Form>
        )
        :
        (
          <RegistroCompletoPresencial correo={correo_registrado} pass={pass_registrado} />
        )
        }
        </Spin>
        </Col>
        </Row>
      </Col>
      </Row>
      <FooterIngenieriaVehicular />
      </>
  );
};

export default withRouter(Signup);
