import React, { useRef, useState, useContext, useEffect } from 'react';
import { Row, Col, Carousel, Form, Typography, Button, Input, Radio, notification } from 'antd';
import '../../assets/css/sala.css';
import {cuestionario} from './preguntas.js';
import '../../assets/css/cuestionario.css';
import {Auth} from '../../utils/AuthContext';

import {guardar_encuesta} from '../../utils/ConexionServidor';
import Footer from '../master/footer/FooterIngenieriaVehicular';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';

const nombre_evento = 'aplicacion-nom012-2da';

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
        duration: 10,
    });
}

const Cuestionario = (props) => {

    const { usuario, datos } = useContext(Auth);

    console.log(cuestionario);

    const collectionToArr = (collection) => {
        var rows = [];
        if (collection && collection.val()) {

            collection.forEach((obj) => {
                let item = obj.val();
                item.key = obj.key;
                rows.push(item);
            });
        }
        return rows;
    }

    useEffect(()=>{
        if(!usuario){
            window.location.href="/";
        }
    },[])

    const Finish = (values) => {
        
        values.correo = datos.correo;
        values.nombre_evento = nombre_evento;
        
        console.log(values);
        guardar_encuesta(values).then((data)=>{
            switch(data.status){
                case 0:
                    mensaje('success','Guardada',data.data);
                    window.location.href="/en-vivo";
                break;
                default:
                    mensaje('error','Error de conexión','Por favor inténtalo en otro momento');
                break;
            }
        });

    }

    let divs_preguntas = cuestionario.map((pregunta) => {

        const radios_respuestas = pregunta.respuestas.map(respuesta => {
            return (
                <p className="p_pregunta_cuestionario">
                    <Row>
                        <Col span={1} style={{textAlign:'center'}}>
                        <input name={`pregunta${pregunta.num_pregunta}`} type="radio" className="radio_pregunta_cuestionario" value={respuesta.respuesta} />
                        </Col>
                        <Col span={23}>
                            <span className="texto_respuesta_cuestionario">
                                {respuesta.respuesta}
                            </span>
                        </Col>
                    </Row>
                    
                </p>
            );
        });

        return (
            
            <Row>
                <Col span={24}>
                <p className="texto_pregunta_cuestionario">
                    <Row>
                        <Col span={1}>
                        {pregunta.num_pregunta}.-
                        </Col>
                        <Col span={22} offset={1}>
                        {pregunta.pregunta}
                        </Col>
                    </Row>
                    </p>
                </Col>
                <Col span={24} style={{textAlign:'left', backgroundColor:'#f3f3f3'}} className="contenedor_parrafo">
                <Form.Item style={{textAlign:'left', width:'100%'}}
                        name={`pregunta${pregunta.num_pregunta}`}
                        label={null}
                        rules={[
                        {
                            required: true,
                            message: 'Selecciona una respuesta',
                        },
                        ]}
                    >
                        <Row>
                            <Col span={24}>
                                { radios_respuestas}
                            </Col>
                        </Row>
                        
                            
                    </Form.Item>
                </Col>
            </Row>
            
        );


    });

    return (
        <>
        <HeaderIngenieriaVehicular />
        <Row>
            <Col xs={{span:22, offset:1}} md={{span:20, offset:2}} lg={{span:18, offset:3}}>
               
            <Form onFinish={Finish}>
                {divs_preguntas}
                <br /><br />
                <button className="btn_enviar_cuestionario" type="primary" htmlType="submit">
          Enviar
        </button>
        <br /><br />
                </Form>
                
            </Col>
        </Row>
        <Footer />
        </>
    );

}

export default Cuestionario;
