import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import img_usuario from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-ID.png';
import img_pass from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-Password.png';

import img_ir_inicio from '../../assets/images/eventos/energia_del_futuro/02-Acceder-webinar-tecnico.png';


const RegistroCompletoPresencial = (props) => {

    const { correo, pass, forma_de_pago } = props;

    return (
        <Row>
            <Col span={24}>
                <p style={{ color: '#2458df', fontWeight: 'bold', textAlign: 'left', fontSize: '40px' }}><br />
                    REGISTRO EXITOSO
                    <hr style={{ backgroundColor: '#2458df', border: '1px solid #2458df' }} />
                </p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>
                    En breve recibirá un correo de confirmación a la siguiente dirección:<br />
                    <span style={{ color: '#2458df' }}><strong>{correo}</strong></span>
                </p>
                <p style={{ color: '#2458df', textAlign: 'left', fontSize: '20px' }}>
                    Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje
                    haya sido enviado a la carpeta de SPAM / NO DESEADOS.
                </p><br />
                <p style={{ backgroundColor: '#2458df', color:'#ffffff', fontSize:'22px' }}>
                    <strong>¡Te esperamos!</strong>
                </p>
            </Col>
            <Col span={24}>
                <br /><br /><br /><br />
            </Col>
        </Row>
    );
}

export default RegistroCompletoPresencial;