import React from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img_logo_a from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_b from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_c from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_d from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_e from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_f from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';
import img_logo_g from '../../assets/images/01_impacDemo_home/05-logo-patrocinador.png';


let logos = [
    { index: 0, img_src: img_logo_a, nombre: '' },
    { index: 1, img_src: img_logo_b, nombre: '' },
    { index: 2, img_src: img_logo_c, nombre: '' },
    { index: 3, img_src: img_logo_d, nombre: '' },
    { index: 4, img_src: img_logo_e, nombre: '' },
    { index: 5, img_src: img_logo_f, nombre: '' },
    { index: 6, img_src: img_logo_g, nombre: '' }
]

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

const CarouselPatrocinadores = (params) => {

    const { num_items } = params;


    const settingslogos = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000
    };

    logos = shuffle(logos);

    const div_logos = logos.map(logo => {
        return(
          <div>
          <img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  />
      </div>
        )
    });

    return (
      <div style={{marginTop:'0px', paddingtop:'0px'}}>
      <Slider {...settingslogos}>
      {div_logos}
      </Slider>
  </div>
      
    );

};

export default CarouselPatrocinadores;