import React, { Component } from "react";
import Slider from "react-slick";
import '../../assets/css/evento.css';

import ponente1 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/version_movil/antp_ingvehicular_Abimael_Movil.jpg';
import ponente2 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/version_movil/antp_ingvehicular_JoseManuel_movil.jpg';
import ponente3 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/version_movil/antp_ingvehicular_JulioOlivares_Movil.jpg';
import ponente4 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/version_movil/antp_ingvehicular_RicardoCortazar_Movil.jpg';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      +
    </div>
  );
}


export default class CarouselBannerMovil extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1,
      autoplaySpeed: 5000,
      cssEase: "linear",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src={ponente1} className="ponente" />
          </div>
          <div>
          <img src={ponente2} className="ponente" />
          </div>
          <div>
          <img src={ponente3} className="ponente" />
          </div>
          <div>
          <img src={ponente4} className="ponente" />
          </div>
        </Slider>
      </div>
    );
  }
}