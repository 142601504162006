import React, { Component, useRef } from "react";
import Slider from "react-slick";
import '../../assets/css/evento.css';

import ponente1 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/antp_ingvehicular_Abimael.jpg';
import ponente2 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/antp_ingvehicular_JoseManuel.jpg';
import ponente3 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/antp_ingvehicular_JulioOlivares.jpg';
import ponente4 from '../../assets/images/eventos/01-ingenierIa-vehicular/ponentes_home/antp_ingvehicular_RicardoCortazar.jpg';


export default class CarouselBanner extends Component {

  ref_carousel = React.createRef();

  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 1,
      autoplaySpeed: 5000,
    };
    return (
      <div>
        <Slider {...settings} ref={this.ref_carousel}>
          <div>
            <img src={ponente1} className="ponente" />
          </div>
          <div>
          <img src={ponente2} className="ponente" />
          </div>
          <div>
          <img src={ponente3} className="ponente" />
          </div>
          <div>
          <img src={ponente4} className="ponente" />
          </div>
        </Slider>
      </div>
    );
  }
}