import React, { useEffect, useContext ,useState} from "react";

import { Button, Menu, Row, Col, Typography, Spin } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import {Preguntas} from '../conferencias/Preguntas'; 
import {Estadisticas} from '../conferencias/Estadisticas';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';
import '../../assets/css/sala.css';

import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

const nombre_evento = 'omnitracs-ultima-milla';

const app = new Firebase(nombre_evento);



let key = '';


const CartaPortev2 =({history}) =>{

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [error_correo, setErrorCorreo] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);


    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                    app.obtener_objetos(nombre_evento+'/preguntas', false, (datos) => {
                        
                        setPreguntas(datos);
                        app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
            break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            correo: datos.correo,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        
        if (usuario===null) {
            //history.push("/login");
        }else{
            if(Object.entries(datos).length !== 0){
                app.obtener_objeto(nombre_evento+'/usuarios-encuesta', 'correo', usuario.email, siguienteEtapa);
            }
            app.obtener_objetos(nombre_evento+'/preguntas-publico',true, (data) => {
                setPreguntasPonente(data);
            });
        }
        
    }, [history, usuario, datos]);

    return (
        <>
        <HeaderIngenieriaVehicular usuario={usuario} />
        <div className="cuerpo">
            <Row justify="center" align="">
            <Col span={0}>
                <CarouselPatrocinadores num_items={6} />
                <br/><br/>
            </Col>
            <Col span={18}>
                <br/><br/>
            <iframe className="video2" width="100%" src="https://www.youtube.com/embed/1rKxrMddQXQ?autoplay=1&rel=0;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col span={24}>
                <br/><br/>
              </Col>
            </Row>
            <Row style={{ display:'none' }}>
                <Col span={24}>
            <Spin tip="Cargando..." spinning={cargando}>
            {
                    etapa === 1 ?
                        (
                            <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} >
                                <Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />
                            </Col>
                        )
                        :
                        (
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }}>
                                <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
                            </Col>
                        )
                }
                </Spin>
                <br/><br/>
                </Col>
                </Row>
        </div>
        
        <FooterIngenieriaVehicular />
                </>
    );

}

export default CartaPortev2;