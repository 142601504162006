import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
} from 'antd';
import  app  from "../../utils/firebaseConfig";
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { Auth } from "../../utils/AuthContext";

import img_crear from '../../assets/images/sitio/registro/01-boton-crear-cuenta.png';

const { Option } = Select;

const Registro = ({ setsignup, history }) => {
    const [error, seterror] = useState("");
    const [otro_medio, setOtroMedio] = useState(false);
    

    const [form] = Form.useForm();

    const ref_form = React.createRef();
    const ref_recaptcha = React.createRef();

    const elegirMedio = value => {
      console.log(value);

      if(value === 'Otro'){
        setOtroMedio(true);
      }else{
        setOtroMedio(false);
      }

    }

    const onFinish = async values => {
        
      if(ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' '){
        return;
      }

        const { correo, password } = values;

        await app.app
        .auth()
        .createUserWithEmailAndPassword(correo, password)
        .then(result => {
          delete values.password;
          delete values.aceptar_terminos;
          delete values.confirm;
          values['g-recaptcha-response'] = ref_recaptcha.current.getValue();
          if(values.medio_enterado === 'Otro'){
            values.medio_enterado = values.otro_medio;
            delete values.otro_medio;
          }
          console.log(correo);
          app.existe_objeto('usuarios','correo',correo, existe => {
            console.log(existe);
            if(!existe){
              app.escribir('usuarios/',undefined,values, () => {
                history.push("/login");
            });
            }
          });

        })
        .catch(error => {

          console.log();
            if(error.message === 'The email address is already in use by another account.'){
              seterror('Éste correo electrónico ya ha sido utilizado para otra cuenta');
            }
            
        });

      };
    return (
        <Row>
         
            <Col xs={{span:18, offset:3}} sm={{span:14, offset:5}} md={{span:12, offset:6}} lg={{span:12, offset:6}}>
            <Form className="login-form"
        style={{backgroundColor:'rgb(245,245,245)'}}
        ref = {ref_form}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish} 
        >

          <Row style={{marginLeft:'20px',marginRight:'20px'}}>
            <Col span={24}>
            <p style={{ textAlign:'left', fontSize:'22px', fontWeight:'bold' }}>
              <br />
                            Registro
                            <hr />
                        </p>
            </Col>
            <Col span={24}>
            <Form.Item
        name="nombre"
        label="Nombre(s)"
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu nombre!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
        name="ap_paterno"
        label="Apellido Paterno"
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido paterno!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="ap_materno"
        label="Apellido Materno"
        rules={[
          
          {
            required: true,
            message: 'Ingresa tu apellido materno!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item
        name="correo"
        label="Correo electrónico"
        rules={[
          {
            type: 'email',
            message: '...',
          },
          {
            required: true,
            message: 'Ingresa tu correo electrónico !',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={11} offset={2}>
            <Form.Item
        name="telefono"
        label="Teléfono celular"
        rules={[
          {
            required: true,
            message: 'Ingresa tu número de celular!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="password"
        label="Elige una contraseña"
        rules={[
          {
            required: true,
            message: 'Ingresa una contraseña!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="confirm"
        label="Confirmar contraseña"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Confirma tu contraseña!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('Asegúrate que las contraseñas coincidan!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="empresa"
        label="Empresa"
        rules={[
          
          {
            required: true,
            message: 'Ingresa el nombre de tu empresa!',
          },
        ]}
      >
        <Input />
      </Form.Item>
            </Col>
            <Col span={24}>
            <Form.Item
        name="medio_enterado"
        label="¿Por qué medio te enteraste?"
        rules={[
          {
            required: true,
            message: 'Elige el medio por el que te enteraste!',
          },
        ]}
      >
        <Select onChange={ elegirMedio } >
          <Option value="Facebook">Facebook</Option>
          <Option value="Twitter">Twitter</Option>
          <Option value="Instagram">Instagram</Option>
          <Option value="Correo electrónico">Correo electrónico</Option>
          <Option value="Whatsapp">Whatsapp</Option>
          <Option value="Otro">Otro</Option>
        </Select>
      </Form.Item>
            </Col>
            <Col span={24}>
            {otro_medio && 
      (
        <Form.Item
        name="otro_medio"
        label="¿Cuál?"
        rules={[
          
          {
            required: true,
            message: 'Ingresa el medio por el que te enteraste!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      )
      }
            </Col>
            <Col span={24} style={{textAlign:'left'}}>
            <Form.Item
        name="aceptar_terminos"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('Acepta el aviso de privacidad'),
          },
        ]}
      >
        <Checkbox>
          Acepto el <a href="">aviso de privacidad</a>
        </Checkbox>
      </Form.Item>
            </Col>
            <Col span={24}>
            <ReCAPTCHA ref={ref_recaptcha}
    sitekey="6LdbzWkUAAAAAJ0HKwuFITRLb25LQDsL50wkYwO_"
  />
            </Col>
            <Col span={24}>
            <Form.Item>
              <br />
              <img style={{ width:'150px', padding:'0px' }} src={img_crear} alt="Entrar" onClick={() => { ref_form.current.submit()}} />
                
                <Button onClick={() => setsignup(false)} type="link" style={{display:'none'}}>
                    Ingresa ahora!
                </Button>
                </Form.Item>
            </Col>
          </Row>
          
        </Form>
            </Col>
        </Row>
        
    );
};

export default withRouter(Registro);
