import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';
import '../../assets/css/sala.css';

import expo1 from '../../assets/images/ponentes_ago24/CUAUHTeMOCVaZQUEZ.png';
import expo2 from '../../assets/images/ponentes_ago24/erikasanchez.png';
import expo3 from '../../assets/images/ponentes_ago24/fcokim.png';
import expo4 from '../../assets/images/ponentes_ago24/lumbrerascastro.png';
import expo5 from '../../assets/images/ponentes_ago24/maangelesfromow.png';
import expo6 from '../../assets/images/ponentes_ago24/adrianhdz.png';
import expo7 from '../../assets/images/ponentes_ago24/fernandoavila.png';
import expo8 from '../../assets/images/ponentes_ago24/enriquevazquez.png';
import expo9 from '../../assets/images/ponentes_ago24/pedroRivera.png';
import expo10 from '../../assets/images/ponentes_ago24/AlejandroSarabiaChavez.png';
import expo11 from '../../assets/images/ponentes_ago24/DrRodrigoOzuna.png';

import { CloseOutlined } from '@ant-design/icons';

const expositor = [
  {nombre: 'Cuauhtémoc Vázquez', tema:'', foto:expo1, boton: '', url:'', semblanza:[<>Licenciado en Derecho y Maestro en Alta Dirección por la Universidad Anáhuac, realizó estudios de Especialidad en Investigación Científica del Delito en la Academia Nacional del FBI, en Quántico, Virginia, EUA. Cuenta con Certificaciones Internacionales en Sistema Penal Acusatorio, por el Centro de Estudios de Justicia de las Américas (CEJA), con sede en Santiago de Chile y por el Institute for Criminal Defense en San Diego California, EUA. Es Maestro y Doctor en Sistema Penal Acusatorio por la Escuela Jurídica y Forense del Sureste, concluyó Doctorado en Derecho Procesal Penal en el Instituto Nacional de Estudios Superiores en Derecho Penal (INDEPAC). Se le otorgó el Doctorado Honoris Causa en la Universidad Nacional Autónoma de México, por parte del Claustro Nacional de Doctores y del Instituto de Investigación Científica de la Seguridad del Estado Mexicano. Realizó los Diplomados de Mando Policial I y II Nivel, en el CIDE, y en la UNAM respectivamente; además del Programa Especializado de Búsqueda de Fugitivos niveles básico, intermedio y avanzado, impartido por los U.S Marshal del Departamento de Justicia de los Estados Unidos de América.
    <br /><br/>
    Es profesor de Derecho Procesal Penal en la Universidad Panamericana; en la Especialidad de Sistema Acusatorio de la Escuela Libre de Derecho; en la Facultad de Derecho, en la Licenciatura de Inteligencia Estratégica, de la Universidad Anáhuac y en la Maestría de Sistema Penal Acusatorio del Consejo de la Judicatura Federal.  Fue designado Profesor Investigador Titular, del Instituto Nacional de Ciencias Penales (INACIPE), en donde realizó la investigación titulada “La Reingeniería al Sistema de Justicia Penal en México”. Es coautor de las obras “El Sistema Penal Acusatorio y El Juicio de Amparo”, “Nuevo Paradigma Policial en el Sistema Penal Acusatorio”, “Las Medidas Cautelares” El Tope a la Puerta Giratoria y “Medidas cautelares. Teoría y práctica”.  
    <br /><br/>
    Fue Fiscal del Ministerio Público Especializado en Homicidios, en la Procuraduría General de Justicia de la Ciudad de México, se desempeñó como Proyectista de Sentencias Penales en el Tribunal Superior de Justicia de la CDMX. Laboró en el Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública, como Director de Formación Especializada y del Servicio Nacional de Carrera. En la Secretaría Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal (SETEC), ocupó los cargos de Director General Adjunto de Capacitación, Difusión y Normatividad. Participó en las mesas de elaboración de CNPP, Fue Comisario en la Policía Federal, Titular la Unidad de Servicios al Sistema de Justicia Penal, de la Policía Procesal y fue el Primer Titular de la Unidad de Seguimiento y Supervisión de Medidas Cautelares y Suspensión Condicional del Proceso (UMECA Federal), asesor también en el Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública, para el diseño, elaboración e implementación del Modelo Nacional de Policía y Justicia Cívica y el IPH 2019. 
    <br /><br/>
    Actualmente es consultor en seguridad y justicia penal, socio fundador del Despacho Consilia Consulting, es socio jurídico en Totaltech en los temas de tecnologías para el sistema de justicia penal, consultor de la Embajada Británica en México en el proyecto de México ACROL para la investigación de delitos de alto impacto, y consultor también del Banco Interamericano de Desarrollo (BID) para proyecto de reingeniería a las Fiscalías en México. </>]},
  {nombre: 'Erika Sánchez', tema:'', foto:expo2, boton: '', url:'', semblanza:[<>Psicóloga egresada de la universidad del valle de México. Cuento con una especialidad en dirección de factura humano. Y cuento con diversos diplomados en grafología, psicología del rostro, y entrevista especializada para detección de mentira. Colaboró en el sector transporte desde hace 26 años. Colabore en TUM transportistas Unidos mexicanos, como jefa de reclutamiento y selección, y los últimos 19 años en Recurso Confiable en diversas posiciones como servicio a clientes, comercial Y actualmente Gerente de Marca</>]},
  {nombre: 'Francisco Kim', tema:'', foto:expo3, boton: '', url:'', semblanza:[<>Jesús Francisco Kim Olguín
    <br /><br />
    Logística y Operaciones Internacionales:<br />
    Fábrica de Jabón la Corona, S.A. de C.V.
    <br /><br />
    Vicepresidente del Sector Multimodal:<br />
    Asociación Nacional de Transporte Privado AC (ANTP)
    <br /><br />
    Maestro en Logística y Negocios Internacionales:<br />
    Por la Universidad Anáhuac del Sur y por la Universitat Abad Oliba - Barcelona
    <br /><br />
    Profesor de Cátedra:<br />
    ITESM Instituto Tecnológico de Estudios Superiores de Monterrey CEM Campus Estado de México División de Negocios
    <br /><br />
    Doctorante:<br />
    Seguridad Internacional Facultad de Estudios Globales Universidad Anáhuac Norte
    <br /><br />
    Diplomado en Logística y Diseño de la Cadena de Suministro: 
    ITESM Instituto Tecnológico de Estudios Superiores de Monterrey CEM Campus Santa Fe
    </>]},
  {nombre: 'Jorge Lumbreras Castro', tema:'', foto:expo4, boton: '', url:'', semblanza:[<>Jorge Lumbreras Castro, es Doctor en Ciencia Política por la UNAM y Maestro en Seguridad Nacional por el Colegio de Defensa Nacional (SEDENA), tiene Diplomados en seguridad, políticas públicas y gobierno. Ha sido profesor de licenciatura y posgrado de la Facultad de Ciencias Políticas (FCPyS-UNAM) por 34 años. También ha sido profesor en los principales centros de estudios militares de México  (IMESDEN, CODENAL y CEEFA) así como de otras universidades de nuestro país y del extranjero (País Vasco y Málaga, España). 
    <br /><br />
    En lo laboral fue Coordinador de Asesores del Procurador de Justicia de la CDMX y Coordinador de Asesores de la Fiscal Especializada para la Atención de Delitos Electorales (FEPADE) de la entonces PGR.  En la Secretaría de Gobernación (SEGOB) ocupó los siguientes cargos: Director General de Organizaciones Sociales, Director General Adjunto de Análisis, Información y Prospectiva, así como asesor en la Subsecretaría de Gobierno. En la SEGOB le designaron integrante del Grupo de Apoyo al Gabinete de Seguridad Nacional. 
    <br /><br />
    En la Secretaría Técnica del Consejo de Implementación del Sistema de Justicia Penal –SETEC- se desempeñó como Director General de Capacitación; Coordinador de Asesores y Director General Adjunto de Difusión. En la Comisión Ejecutiva de Atención a Víctimas fue Coordinador de Comunicación Social. Además ha tenido otros encargos en el Gobierno Federal en el Instituto Mexicano del Seguro Social, el Sistema Nacional DIF, y las entonces Secretarías de Desarrollo Social y Programación y Presupuesto a lo largo de 30 años.  
    <br /><br />
    Es autor de dos libros, colaborador en diferentes medios de comunicación, y ha publicado más de 200 artículos y columnas especializadas en seguridad y justicia, seguridad nacional y derechos humanos. Actualmente trabaja en la Subsecretaría de Seguridad Pública de la SSPC del Gobierno Federal.  </>]},
  /*{nombre: 'Ma. de los Angeles Fromow ', tema:'', foto:expo5, boton: '', url:'', semblanza:[<ul>
    <li>Destacada abogada y académica mexicana egresada de la Universidad Nacional Autónoma de México. Cursó la Maestría en Salud Pública por el Instituto Nacional de Salud Pública y, en el extranjero, su formación se distingue por la maestría en  Tributación Fiscal por el Centro de Estudios Financieros de Madrid, y el doctorado en Derecho por la Universidad Complutense de Madrid, España.</li>
<li>En el ámbito académico, ha sido profesora de licenciatura y posgrado en Derecho Administrativo y Estructura Jurídico-Política del Estado Mexicano, en la Facultad de Derecho de la Universidad Nacional Autónoma de México. Desde hace más de dos décadas, su trayectoria profesional ha sido notable en distintas áreas del derecho como el corporativo, administrativo, penal, electoral y fiscal.</li>
<li>En el sector público federal fue Titular de la Fiscalía Especializada para la Atención de Delitos Electorales (FEPADE), de la entonces Procuraduría General de la República (PGR); Titular de la Unidad Coordinadora de Vinculación y Participación Social de la Secretaría de Salud y, Titular de la Secretaría Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal Acusatorio de la Secretaría de Gobernación.</li>
<li>Su experiencia laboral en el sector privado ha sido como abogada corporativa y de relaciones institucionales en grupos empresariales trasnacionales como Grupo Empresarial Cemex, Grupo Maseca, y MetLife México.</li>
<li>Es experta en el diagnóstico, diseño, implementación y evaluación de estrategias integrales en temas políticos, jurídicos y legislativos. Así como en el desarrollo de tácticas de cabildeo para impulso, seguimiento o contención de iniciativas, emisión de opiniones sobre el impacto y alcance jurídico de disposiciones regulatorias, asesoría para generar propuestas técnicas de mejora del marco legislativo o regulatorio, así como en la elaboración y monitoreo de iniciativas, en el ámbito federal o local.</li>
<li>Destaca su desempeño y experiencia en los ámbitos de seguridad y justicia para articular esfuerzos sobre el combate a la delincuencia y prevención del delito. Ha logrado articular esfuerzos entre instituciones del sector público y privado en temas de prevención del delito y violencia de género. Ha realizado actividades de reestructuración de modelos de atención a víctimas y capacitación para atención del fenómeno de la violencia.</li>
<li>Así como en la regulación concerniente al transporte, donde se ha caracterizado por el impulso de políticas públicas y propuestas legislativas a nivel nacional y estatal, en tópicos administrativos, de movilidad, seguridad vial y fiscales. Resaltan su colaboración con distintos organismos empresariales como la Asociación Nacional de Transporte Privado (ANTP), AMANAC, CAAAREM, CONCAMIN, COPARMEX Nacional, Centro Empresarial de la Ciudad de México y ASUME.</li>
<li>Entre sus últimas publicaciones destacan: “COVID-19 y su circunstancia: Una visión jurídica plural de la pandemia”, Vol. IV Política, derecho y economía. Editado por el IIJ-UNAM y el Instituto de Estudios Constitucionales del Estado de Querétaro. México 2021; “Escenarios de violencia contra las mujeres. Hacia la construcción de propuestas para su erradicación”, INACIPE. Primera Edición 2021; “Ley General en Materia de Delitos Electorales. Comentada”, Fiscalía Especializada en Delitos Electorales del Estado de Nuevo León. Editorial Tirant Lo Blanch. Ciudad de México, 2021; y “Emergencia Sanitaria por COVID-19: Reflexiones desde el derecho (II), Opiniones Técnicas sobre Temas de Relevancia Nacional”, Núm. 15, publicado por el Instituto de Investigaciones Jurídicas, México, mayo, 2020.</li>
<li>Ha recibido diversos reconocimientos y distinciones por su trayectoria profesional y su contribución a la cultura de la legalidad, derechos humanos, democracia, gobernanza e implementación de la reforma penal.</li>
<li>10. Actualmente, es socia fundadora de MAF & Asociados una consultoría especializada en materia corporativa, jurídica y de prospectiva legislativa, así como en el ámbito electoral, de comunicación y relaciones públicas para negocios, asociaciones y cámaras empresariales, instituciones gubernamentales y organizaciones de la sociedad civil.</li>
  </ul>]},*/
  {nombre: 'Adrian Hernández', tema:'', foto:expo6, boton: '', url:'', semblanza:[<>
  Preparación profesional: Egresado del Instituto Tecnológico de Estudios Superiores de Occidente (ITESO) con Licenciatura en Comercio y Negocios Globales, Diplomado en Black Belt Sx Sigma, Diplomado en Administración de Materiales y Logística, certificación internacional ISO 31000 Risk Manager e ISO 28000 Supply Chain Lead Auditor. Curso de negociación comercial por la Universidad de Michigan, Marketing digital por la IAB Spain. Certificado como consultor de gestión estratégica de negocios para PYMES por FOJAL.<br /><br />
Compañías empleadoras en mi recorrido profesional:
<ul>
<li>Adualink VMI Services</li>
<li>Hortifrut</li>
<li>Crane Worldwide Logistics</li>
<li>Plexus Electrónica</li>
</ul>
He trabajado para proyectos multinacionales por mencionar algunos de los más importantes como CEVA Logistics, DB Schenker, Crane Worlwide logistics, Hortifrut, Cisco Systems, Plexus Corp., involucrado en sectores industriales automotriz, comunicaciones, bienes de capital, electrónica, alimenticia y médica.<br /><br />
<ul>
<li>10 años de experiencia en proyectos de almacenaje y distribución, cadena de frío, gestión aduanera, mejora del desempeño de la cadena de suministro, gestión de riesgos de la cadena de suministro, implementación de programas de seguridad para la cadena de suministros, gestión logística y la mejora continua.</li>
<li>7 años de experiencia gestionando proyectos freelance de consultoría de negocios con enfoque al desarrollo de proyectos al comercio exterior y proyectos de importación y exportación, así como la mejora en sistemas de gestión de desarrollo de la cadena de suministro y planeación estratégica de empresas para el desarrollo de negocios.</li>

<li>He participado en ponencias relacionadas con la certificación IVA & IEPS para la revista T21 y sistemas de gestión integral de seguridad para ALAS x México, por mencionar algunas.</li>

<li>Profesor invitado en el Instituto Tecnológico de Estudios Superiores de Occidente (ITESO) para las materias de regulaciones y restricciones no arancelarias, operación aduanera, compras internacionales y supply chain. He participado como profesor adjunto para la Universidad Anahuac de Oaxaca (virtual) del diplomado Supply Chain en los módulos de almacenes y centros de distribución y la mejora continua en la logística.</li>
</ul>
Actualmente como Mx Trade Compliance Supv. Para PLEXUS ELECTRÓNICA, responsable de la planeación, ejecución y evaluación del programa de seguridad para la cadena de suministros, así como la gestión del programa de gestión de cumplimiento en materia aduanera para México.
</>]},
  {nombre: 'Fernando Ávila', tema:'', foto:expo7, boton: '', url:'', semblanza:[<>Fernando Ávila Sánchez se ha destacado profesionalmente    por su trayectoria sólida y diversificada en la industria automotriz y de nuevas tecnologías.    <br /><br />
    Su formación académica es como Ingeniero Civil egresado de la Universidad Anáhuac México; tiene como experiencia varios cargos como Especialista OnCommand en Navistar, Gerente de Capacitación Operativa en Estrella Blanca y como Gerente de Foton Telematics.
    <br /><br />
    Su pasión por la innovación lo ha llevado a especializarse en capacitación de flotas a través de Telemetría, resaltando que actualmente tiene el cargo de Director General de Advance, siendo también cofundador de esta empresa en México.
    <br /><br />
    Gracias a su enfoque proactivo y espíritu entusiasta, Fernando se ha posicionado como un líder que busca la constante transformación, el impulso y la visibilización del sector automotriz, a través del aprovechamiento de las nuevas tecnologías y herramientas que ofrece el mercado, evolucionando sus marcas hacia un crecimiento sostenible.</>]},
  {nombre: 'Enrique Vázquez', tema:'', foto:expo8, boton: '', url:'', semblanza:[<>Enrique Vázquez Rebolledo es Licenciado en Administración de Empresas, egresado del Tecnológico de Monterrey Campus Estado de México.
    <br /><br />
    En el año 1997 funda Recurso Confiable, empresa que desde sus inicios ofrece soluciones de Investigación y Validación de la trayectoria de Operadores de Transporte de Carga con el objetivo de incrementar la seguridad dentro de la industria; en el año 2006, entendiendo y dando atención a las necesidades del mercado, e influenciado por la ola tecnológica, crea y desarrolla una Plataforma de Visibilidad Logística que brinda tracking en tiempo real de las unidades, visibilidad, monitoreo, predictibilidad e inteligencia para la toma de decisiones oportuna y asertiva.
    <br /><br />
    A lo largo de los años, como líder de la compañía, ha hecho crecer a Recurso Confiable dentro y fuera de México; durante el año 2011 inicia operaciones en Guatemala, en el año 2012 abre mercado en Colombia y en el 2015 extiende su presencia a Costa Rica. Además, durante el año 2017 incursiona en el mercado norteamericano ofreciendo soluciones Logísticas bajo el nombre de We’RControl. Desde el año 2018, convencidos en que la adopción y el uso de la tecnología es primordial para el crecimiento de la industria, dentro de Recurso Confiable se implementaron herramientas de Inteligencia Artificial y Big Data para seguir ofreciendo soluciones eficientes y que resuelven en menor tiempo y mayor calidad las necesidades de nuestros clientes.
    <br /><br />
    Hoy, Recurso Confiable es una empresa líder en la industria con más de 27 años en el mercado, que ofrece servicios que ayudan a agilizar, optimizar y contribuyen a automatizar las operaciones logísticas.
    <br /><br />
    Además desde el año 2021 Enrique es Presidente del Consejo de Control Match, disruptiva plataforma que aplica modelos tecnológicos que sirven para conectar a generadores de carga con transportistas en un marketplace innovador y lleno de oportunidades y beneficios (seguro de carga, pronto pago, carta porte, logística verde, backhaul, entre otros).
    <br /><br />
    La visión de Enrique siempre ha sido tener el equilibrio perfecto entre la gente y la tecnología para crear herramientas de vanguardia que ayuden a llevar a la industria al siguiente nivel tanto nacional como internacional.</>]},
    {nombre: 'Pedro Rivera Benard', tema:'', foto:expo9, boton: '', url:'', semblanza:[<>Ingeniero Industrial egresado de la Universidad Panamericana, con más de 23 años de experiencia, 15 de ellos en multinacionales de consumo masivo, destacándose en Cadbury Adams y Nestlé.<br /><br />
      En 2016, fundó un fondo de capital privado respaldado por 15 inversionistas nacionales e internacionales, enfocado en la inversión en empresas de tecnología. Desde entonces, y como  resultado de las inversiones realizadas por el fondo, Pedro se desempeña los últimos 7 años, como CEO de TecnoMotum, una empresa líder en conectividad de flotas, inteligencia artificial y seguridad robotizada.</>]},
      {nombre: 'Alejandro Sarabia Chávez', tema:'', foto:expo10, boton: '', url:'', semblanza:[<>Alejandro Sarabia Chávez nació en Culiacán, Sinaloa, en 1984. Es Licenciado en Estudios Internacionales por la Universidad Autónoma de Sinaloa. Actualmente, se desempeña como Gerente de Ventas para Samsara México.<br /><br />
        Alejandro cuenta con más de 15 años de experiencia en ventas en diversas industrias, destacándose en la Logística Express y de Última Milla, así como en el Transporte de Pasajeros. Su trayectoria profesional refleja un sólido conocimiento y liderazgo en estos sectores clave, siempre orientado a la innovación y el desarrollo de soluciones efectivas para sus clientes y equipos.</>]},
      {nombre: 'Rodrigo Arturo Ozuna ', tema:'', foto:expo11, boton: '', url:'', semblanza:[<>Con más de 25 años de experiencia en el servicio público en el análisis y elaboración de proyectos normativos, desarrollos procedimentales, esquemas de cumplimiento normativo y procesos legislativos.
        <br /><br />
        Experiencia Profesional
        <ul>
        <li>Actualmente Consultor en MAF & Asociados, en temas de Compliance y esquemas normativos.</li>
        <li>Director General Jurídico y de Estudios y Proyectos Normativos, así como Coordinador de Asesores de la Secretaría Técnica del Consejo de Coordinación para la Implementación del Sistema de Justicia Penal de la SEGOB.</li>
        <li>Secretario Técnico en Ponencia de Consejero de la Judicatura Federal, encargado de diagnósticos normativos y procedimientos disciplinarios.</li>
        <li>Dictaminador I en la Unidad General de Enlace con los Poderes Federales en la Suprema Corte de Justicia de la Nación. Responsable del procesos de reforma constitucional y legal relacionados con el Poder Judicial de la Federación.</li>
        <li>Asesor Legislativo y Secretario Técnico con amplia experiencia en el Senado de la República y la Cámara de Diputados, participando en importantes reformas constitucionales y legales en materia de justicia.</li>
        <li>Consultor en la estrategia de armonización normativa del Código Nacional de Procedimientos Civiles y Familiares del Poder Judicial del Estado de México y en otros Poderes Judiciales.</li>
        <li>Abogado Litigante en procesos de responsabilidad administrativa, penales acusatorios y amparo.</li>
        <li>Vicepresidente  en el Colegio Mexicano de Abogados Humanistas y presidente de la Comisión Procesal Penal.</li>
        <li>Docente certificado del sistema penal acusatorio, catedrático, conferencista y panelista en diversos foros relacionados con la implementación de sistemas procesales de justicia. Autor de obras relacionadas con los ámbitos normativos del Código Nacional de Procedimientos Penales y de la Ley Federal contra la Delincuencia Organizada.</li>
        </ul>
        
        Proyectos Relevantes
        <ul>
        <li>Coordinador en la propuesta del Código Federal de Procedimientos Penales y participante en la elaboración del Código Nacional de Procedimientos Penales y la miscelánea penal. Encargado de la estrategia de armonización normativa del Código a nivel nacional.</li>
        <li>Participación en la confección y procesamiento de reformas al Poder Judicial de la Federación.</li>
        <li>Coordinador en la Judicatura Federal y la Suprema Corte de Justicia de la Nación en la implementación y armonización normativa de proyectos relevantes.</li>
        <li>Integrante de la delegación mexicana ante la Comisión Interamericana de los Derechos Humanos en el tema “Desafíos en la Implementación del Sistema de Justicia Penal en México”.</li>
        <li>Coordinador General del Grupo de Trabajo México – Canadá sobre ética judicial en el Proyecto de Sensibilización y Capacitación de Jueces. </li>
        <li>Coordinador General del Grupo de Trabajo: México – Colombia, en la implementación del Sistema de Justicia Penal. </li>
        <li>Articulador del proceso de armonización normativa del Código Nacional de Procedimientos Civiles y Familiares en el Poder Judicial del Estado de México.</li>
        </ul></>]},
]
const Ponentes = (props) =>{
  const [ver_texto, setVertexto] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let visualizar_ponentes = expositor.map((expo, index)=>{
    return(
      <>
      {expo.semblanza != null ?
      <Col xs={10} md={5} onClick={()=>(setVertexto(index),setIsModalVisible(true))} style={{cursor:'pointer', margin:'10px'}} >
        <img src={expo.foto} style={{width:'100%', cursor:''}} />
        <p className='nombre_ponente_2'>{expo.nombre}</p>
        <p className='link_ponente'>Ver semblanza</p>
      {/*<div className="contenedor_imagen_con">
          <img src={expo.foto} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con">{expo.nombre}</p>
              <br />
              <p style={{display:'non'}}>
                {expo.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setVertexto(index);
                }}
                className="btn_desc_con"
              >
                Leer semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>*/}
    </Col>
    :
    <Col xs={10} md={4}>
        <img src={expo.foto} style={{width:'100%', cursor:''}} />
      </Col>}
      </>
    );
  });
  let ver_texto_modal = expositor.filter((item,index) => {
    if(index === ver_texto){
      return true;
    }else{
      return false;
    }
  }).map((texto,index)=>{
    return(
      <>
      <p className='nombre_ponente'>{texto.nombre}</p>
    <p className='texto_ponente'>{texto.semblanza}</p>
    </>
    )
  })

  return(
    <>
    <Row justify={'center'} style={{padding:'3% 0%'}}>
      <Col xs={{span:20}}>
        <p className='titulo_ponente'>PONENTES</p>
        <Row justify={'center'}>
      {visualizar_ponentes}

        </Row>
      </Col>
    </Row>
    <Modal
        open={isModalVisible}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
}
export default Ponentes;