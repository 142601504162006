import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import img_usuario from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-ID.png';
import img_pass from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-Password.png';

import img_ir_inicio from '../../assets/images/eventos/energia_del_futuro/02-Acceder-webinar-tecnico.png';


const RegistroCompletoAplicacionNom012 = (props) => {

    const { correo, pass, forma_de_pago } = props;

    return (
        <Row>
            <Col span={24}>
                <p style={{ color: '#112f6d', fontWeight: 'bold', textAlign: 'left', fontSize: '40px' }}>
                    REGISTRO EXITOSO
                    <hr style={{ backgroundColor: '#349800', border: '1px solid #349800' }} />
                </p>
                <p style={{ textAlign: 'left', fontSize: '20px' }}>
                    En breve recibirá un correo de confirmación a la siguiente dirección:<br />
                    <span style={{ color: '#349800' }}><strong>{correo}</strong></span>
                </p>

                <p style={{ color: '#112f6d', textAlign: 'left', fontSize: '20px' }}>
                    Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje
                    haya sido enviado a la carpeta de SPAM / NO DESEADOS.
                </p>

                <br />
                <p style={{ color: '#112f6d', textAlign: 'left', fontSize: '20px' }}>
                    Instrucciones de acceso.
                </p>

                <Row style={{ color: '#112f6d', fontSize: '20px' }}>
                    <Col span={1} style={{ color: '#349800' }}>
                        <p><strong>1</strong></p>
                    </Col>
                    <Col span={23} style={{ textAlign: 'left' }}>
                        <p>Ingresa a  <strong><a href="https://webinars.antp.org.mx/" target='_blank'>https://webinars.antp.org.mx/</a></strong></p>
                    </Col>
                    <Col span={1} style={{ color: '#349800' }}>
                        <p><strong>2</strong></p>
                    </Col>
                    <Col span={23} style={{ textAlign: 'left' }}>
                        <p>Copia tu  <strong>usuario (correo electrónico)</strong> y pégalo en la casilla correspondiente.</p>
                    </Col>
                    <Col span={1} style={{ color: '#349800' }}>
                        <p><strong>3</strong></p>
                    </Col>
                    <Col span={23} style={{ textAlign: 'left' }}>
                        <p>Repite el paso anterior con tu <strong>contraseña</strong></p>
                    </Col>
                    <Col span={1} style={{ color: '#349800' }}>
                        <p><strong>4</strong></p>
                    </Col>
                    <Col span={23} style={{ textAlign: 'left' }}>
                        <p>Da clic en  <strong>ingresar</strong> y disfruta del evento.</p>
                    </Col>
                </Row>

                <p style={{ color: '#112f6d', textAlign: 'left', fontSize: '20px' }}>
                    <strong>Tus datos de acceso</strong>
                </p>

                <Row justify="space-around" align="middle">
                    <Col span={2}>
                        <p><img src={img_usuario} style={{ width: '100%' }} /></p>
                    </Col>
                    <Col span={22} style={{ textAlign: 'left', fontSize: '20px' }}>
                        <p style={{ color: '#112f6d' }}>Tu usuario (correo electrónico): <br /><strong>{correo}</strong></p>
                    </Col>
                    <Col span={2}>
                        <p><img src={img_pass} style={{ width: '100%' }} /></p>
                    </Col>
                    <Col span={22} style={{ textAlign: 'left', fontSize: '20px' }}>
                        <p style={{ color: '#112f6d' }}>Contraseña: <br /><strong>{pass}</strong></p>
                    </Col>

                    {forma_de_pago === 'Tarjeta de crédito o débito' &&
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <p style={{ color: '#112f6d', textAlign: 'left', fontSize: '20px' }}>
                                Al concluir tu registro, el sistema abrirá una nueva pestaña para realizar el pago en la plataforma PayPal. En caso de no abrirse, da clic <a href="https://www.paypal.com/paypalme/antpmx/568.40" target="_blank">aqui.</a>
                            </p>
                        </Col>
                    }

                    <Col span={24}>
                        <br /><br /><br />
                        <p style={{ backgroundColor: '#349800', color: '#112f6d', textAlign: 'center', fontSize: '16px', padding: '10px' }}><strong>Nota: </strong> La transmisión se habilita 30 min. antes del evento.</p>

                    </Col>

                </Row>

            </Col>
            <Col span={24}>
                <br /><br /><br /><br />
            </Col>
        </Row>
    );
}

export default RegistroCompletoAplicacionNom012;