import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../../assets/css/ingenieria/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ima from '../../../../src/assets/images/eventos/01-ingenierIa-vehicular/01-logo-antp.png';
import registro from '../../../../src/assets/images/01_impacDemo_home/01_boton-registro.png';
import CarouselPatrocinadores from '../../evento/CarouselPatrocinadores';

import rede_facebook from '../../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/facebook.png';
import rede_twitter from '../../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/instagram.png';
import rede_instagram from '../../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/twitter.png';
import rede_youtube from '../../../assets/images/eventos/01-ingenierIa-vehicular/iconos-redes/youtube.png';


const FooterIngenieriaVehicular = () => {
  return(
    <Row justify="center" align="top" className="footer">
      <Col xs={20} md={20} style={{ padding: '3% 0% 0% 0%' }}>
        <img alt="logo" style={{width:'200px'}} src={ima}/><br/>
        </Col>
      <Col xs={20} md={20} style={{ padding: '1% 0% 0% 0%' }}>
        <p className="text-azul"><strong><a style={{ color:'#0d3159', textDecoration:'none' }} href="https://www.antp.org.mx/">www.antp.org.mx</a><br/>Av. Insurgentes Sur #813-7, Nápoles, CDMX</strong></p>
        <p className="text-azul"><a style={{ color:'#0d3159', textDecoration:'none' }} href="tel:+525555343598"><strong>+52 (55) 5534 3598</strong></a></p>
        
        </Col>
        <Col xs={20} md={20} style={{ padding: '0% 0% 3% 0%' }}>
        <p className="text-azul">Síguenos:</p>
        <Row>
          <Col className="redes" span={3} offset={6}>
          <a href="https://www.facebook.com/antpmx/" target="_blank"><img className="redes" src={rede_facebook} /></a>
          </Col>
          <Col className="redes" span={3}>
          <a href="https://www.instagram.com/antp_mx/" target="_blank"><img className="redes" src={rede_twitter} /></a>
          </Col>
          <Col className="redes" span={3}>
          <a href="https://twitter.com/antpmexico" target="_blank"><img className="redes" src={rede_instagram} /></a>
          </Col>
          <Col className="redes" span={3}>
          <a href="https://www.youtube.com/channel/UCQceWYoxh0kiHnQUhKttUTA" target="_blank"><img className="redes" src={rede_youtube} /></a>
          </Col>
        </Row>
      </Col>
      <Col span={0}>
        <Row>
          <Col span={24} >
            <p className="tituLogos">
              <strong>AGRADECEMOS A NUESTROS PATROCINADORES:</strong>
            </p>
          </Col>
          <Col span={24}>
          <CarouselPatrocinadores num_items={6} />
            <br/><br/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FooterIngenieriaVehicular;