import React from 'react';
import Firebase from '../../utils/firebaseConfig';
import { Redirect } from 'react-router-dom'; 

const app = new Firebase('antp_ingenieria_vehicular');
const app_2 = new Firebase('antp_reformas_penales');

const CerrarSesion = () => {

    app.app.auth().signOut();
    app_2.app.auth().signOut();

    return(
        <Redirect to="/" />
    );

}

export default CerrarSesion;
