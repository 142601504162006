import React, { useRef, useState, useContext, useEffect } from 'react';
import { Row, Col, Carousel, Form, Typography, Button, Input, Radio, notification } from 'antd';
import '../../assets/css/sala.css';
import {cuestionario} from './preguntas.js';
import '../../assets/css/cuestionario.css';
import {Auth} from '../../utils/AuthContext';

import { encuesta_contestada } from '../../utils/ConexionServidor';

import {guardar_encuesta} from '../../utils/ConexionServidor';
import Footer from '../master/footer/FooterIngenieriaVehicular';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';

const nombre_evento = 'aplicacion-nom012';

const mensaje = (tipo, titulo, texto) => {
    notification[tipo]({
        message: titulo,
        description: texto,
        duration: 10,
    });
}

const Cuestionario = (props) => {

    const [etapa_diploma, setEtapaDiploma] = useState(0);
    const [datos, setDatos] = useState(null);

    const finishCorreo = (values) => {
        
        encuesta_contestada(nombre_evento,values.correo).then(({status, data}) => {

            console.log(data);
            switch(status){
                case 0:
                    setDatos(data);
                    setEtapaDiploma(1)
                    break;
                case 1:
                    setDatos(data);
                    setEtapaDiploma(2)
                    break;
                default:
                    mensaje('warning','Correo no encontrado','Éste correo no cuenta con un registro, por favor verifica tus datos');
                    break;
            }
        })

    }

    const Finish = (values) => {
        
        values.correo = datos.correo;
        values.nombre_evento = datos.nombre_curso+'-after';
        
        guardar_encuesta(values).then((data)=>{
            switch(data.status){
                case 0:
                    mensaje('success','Guardada',data.data);
                    setEtapaDiploma(2);
                break;
                default:
                    mensaje('error','Error de conexión','Por favor inténtalo en otro momento');
                    setEtapaDiploma(1);
                break;
            }
        });

    }

    let divs_preguntas = cuestionario.map((pregunta) => {

        const radios_respuestas = pregunta.respuestas.map(respuesta => {
            return (
                <p className="p_pregunta_cuestionario">
                    <Row>
                        <Col span={1} style={{textAlign:'center'}}>
                        <input type="radio" name={`pregunta${pregunta.num_pregunta}`} className="radio_pregunta_cuestionario" value={respuesta.respuesta} />
                        </Col>
                        <Col span={23}>
                            <span className="texto_respuesta_cuestionario">
                                {respuesta.respuesta}
                            </span>
                        </Col>
                    </Row>
                    
                </p>
            );
        });

        return (
            
            <Row>
                <Col span={24}>
                <p className="texto_pregunta_cuestionario">
                    <Row>
                        <Col span={1}>
                        {pregunta.num_pregunta}.-
                        </Col>
                        <Col span={22} offset={1}>
                        {pregunta.pregunta}
                        </Col>
                    </Row>
                    </p>
                </Col>
                <Col span={24} style={{textAlign:'left', backgroundColor:'#f3f3f3'}} className="contenedor_parrafo">
                <Form.Item style={{textAlign:'left', width:'100%'}}
                        name={`pregunta${pregunta.num_pregunta}`}
                        label={null}
                        rules={[
                        {
                            required: true,
                            message: 'Selecciona una respuesta',
                        },
                        ]}
                    >
                        <Row>
                            <Col span={24}>
                                { radios_respuestas}
                            </Col>
                        </Row>
                        
                            
                    </Form.Item>
                </Col>
            </Row>
            
        );


    });

    return (
        <>
        <HeaderIngenieriaVehicular />
        <Row>
            <Col span={24}>
                <p style={{fontSize:'30px'}}>CONSTANCIA</p>
            </Col>
        </Row>
        {etapa_diploma === 0 ?
<Row>
    <Col xs={{span:22, offset:1}} md={{span:8, offset:8}} style={{textAlign:'center'}}>
        <p>Por favor compártenos tu correo electrónico</p>
<Form layout="vertical" onFinish={finishCorreo}>

<Form.Item style={{textAlign:'left', width:'100%'}}
                        name={`correo`}
                        label={'Correo electrónico con el cuál te registraste'}
                        rules={[
                        {
                            required: true,
                            message: 'Registra tu correo',
                        },
                        ]}
                    >
                    <Input />
                    </Form.Item>

<br /><br />
<button className="btn_enviar_cuestionario" type="primary" htmlType="submit">
Buscar
</button>
<br /><br />
</Form>
</Col>
</Row>
:
etapa_diploma === 1 ?
        <Row>
        <Col xs={{span:22, offset:1}} md={{span:20, offset:2}} lg={{span:18, offset:3}}>
            <br />
            <p style={{fontSize:'20px', fontWeight:'bold'}}>Por favor contesta la encuesta antes de obtener tu constancia</p>
           <br />
        <Form onFinish={Finish}>
            {divs_preguntas}
            <br /><br />
            <button className="btn_enviar_cuestionario" type="primary" htmlType="submit">
      Enviar
    </button>
    <br /><br />
            </Form>
            
        </Col>
    </Row>
    :
    <Row>
        <Col span={24}>
            <p style={{marginTop:'100px', marginBottom:'100px'}}>
                <span style={{fontSize:'30px', fontWeight:'bold'}}>GRACIAS POR PARTICIPAR EN NUESTRO CURSO</span>
                <br /><br /><br />
                <a target="_blank" href={`https://www.antp.org.mx/webinar_src/aplicacion_nom012/diplomas/${datos.folio}.pdf`} className="boton_descargar_diploma" download>Descarga tu constancia</a>
            </p>
        </Col>
        <Col span={24}>
            <iframe src="https://issuu.com/antpmx/docs/antp_nom_012_v17febrero/1?ff" style={{width:'90%', height:'600px'}} />
        </Col>
    </Row>
        }
        
        <Footer />
        </>
    );

}

export default Cuestionario;
