import React from 'react';
import { Row, Col } from 'antd';

const RegistroCompletoSinPago = (props) => {

    const {correo} = props;

    return(
        <Row>
            <Col span={24}>
                <br />
                <p style={{fontSize:'20px'}}>Gracias por tu registro<br />
    El correo con el que te registraste es <br />
     <strong>{correo}</strong>
     <br /><br />
     Pronto te notificaremos del acceso al webinar.
     <br /><br /> <br />
    </p>
            </Col>
        </Row>
    );
}

export default RegistroCompletoSinPago;