import React, {useState, useEffect, useContext, useRef} from 'react';
import '../../assets/css/admin.css';
import Firebase from '../../utils/firebaseConfig';
import { Row, Col, Select, notification, Modal, Form, Input, Button  } from "antd";
import { Auth } from "../../utils/AuthContext";
import { CheckCircleOutlined, CloseCircleOutlined, UndoOutlined, DeleteOutlined, EditOutlined, ConsoleSqlOutlined  } from '@ant-design/icons';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

const nombre_webinar = '1-coloquio-transporte-multimodal';

const mensaje = (tipo, titulo, texto) =>{
  notification[tipo]({
      message: titulo,
      description: texto,
      duration: 10,
    });
}

const firebase = new Firebase();

const { Option } = Select;

const acceso_admin = ['Martin@thethree.com.mx','martin@thethree.com.mx','martin@thethree.com.mxx','martha@thethree.com.mx','marco@thethree.com.mx',
                      'andy@thethree.com.mx','mario@thethree.com.mx','federico@zeraus.com.mx',
                      'j.villasenor1021@gmail.com'];

const Admin = (props) => {

  const [questions, setQuestions] = useState([]);
  const[respuesta, setRespuesta] = useState(null);
  const [mostrar_modal_respuesta, setMostrarModalRespuesta] = useState(false);
  const [respuestas, setRespuestas] = useState([]);
  const [questions1, setQuestions1] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [questions4, setQuestions4] = useState([]);
  const [questions5, setQuestions5] = useState([]);
  const [respuestas1, setRespuestas1] = useState([]);
  const [respuestas2, setRespuestas2] = useState([]);
  const [respuestas3, setRespuestas3] = useState([]);
  const [respuestas4, setRespuestas4] = useState([]);
  const [respuestas5, setRespuestas5] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [dia, setDia] = useState(1);
  const [pop_pregunta, setPopPregunta] = useState(-1);
  const [ver_video, setVerVideo] = useState(1);
  const [dia_fire, setDiaFire] = useState(1);
  const ref_form_respuesta = useRef(null);
  const [video, setVideo] = useState(1);
  const [encuesta, setEncuesta] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [usuarios_encuesta, setUsuariosEncuesta] = useState([]);

  const { usuario, datos } = useContext(Auth);

  useEffect(()=>{

   
    if(usuario){

      firebase.obtener_objetos(nombre_webinar+`/configuracion`,false,(data)=>{
        
        setPopPregunta(data[0].pregunta_popup);
        setDia(data[0].dia);
        setVerVideo(data[0].ver_video);
        setDiaFire(data[0].dia);
        setEncuesta(data[0].encuesta);

        firebase.obtener_objetos(nombre_webinar+`/usuarios-encuesta`,true,(data)=>{
          setUsuariosEncuesta(data);
        });
        

        firebase.obtener_objetos(nombre_webinar+`/usuarios`,true,(data)=>{
          setUsuarios(data);
        });

        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico1`,true,(data)=>{
            setQuestions1(data);
        });
        /*firebase.obtener_objetos(nombre_webinar+`/preguntas-publico2`,true,(data)=>{
            setQuestions2(data);
        });*/
        /*firebase.obtener_objetos(nombre_webinar+`/preguntas-publico3`,true,(data)=>{
            setQuestions3(data);
        });
        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico4`,true,(data)=>{
            setQuestions4(data);
        });
        firebase.obtener_objetos(nombre_webinar+`/preguntas-publico5`,true,(data)=>{
          setQuestions5(data);
        });*/

      firebase.obtener_objetos(nombre_webinar+`/respuestas-admin1`,true,(data)=>{
        setRespuestas(data);
      });

      firebase.obtener_objetos(nombre_webinar+'/preguntas-popup',false,(data) => {
        setPreguntas(data);
      });
  
      });
      if(datos){
        const acceso = acceso_admin.filter(correo => correo === datos.correo).length;
        
        if(acceso > 0){
          firebase.obtener_objetos(nombre_webinar+`/configuracion`,false,(data)=>{
            setPopPregunta(data[0].pregunta_popup);
            setEncuesta(data[0].encuesta);
            setDia(data[0].dia);
            firebase.obtener_objetos(nombre_webinar+`/preguntas-publico${data[0].dia}`,true,(data)=>{
              setQuestions(data);
              
            });
            firebase.obtener_objetos(nombre_webinar+'/preguntas-popup',false,(data) => {
              setPreguntas(data.filter(pregunta => pregunta.dia === data[0].dia));
            });
          });
        }else{
          //alert('no tienes acceso a esta seccion');
        }
      }else{
        //alert('no tienes acceso a esta seccion');
      }
    }else{
      //alert('no tienes acceso a esta seccion');
    }
  },[usuario,datos]);

  useEffect(()=>{
    if(dia === 1 || dia === 5){
      setVideo(1);
    }
  },[dia]);

  useEffect(()=>{
    switch(dia){
      case 1: setQuestions(questions1); break;
      case 2: setQuestions(questions2); break;
      case 3: setQuestions(questions3); break;
      case 4: setQuestions(questions4); break;
      case 5: setQuestions(questions5); break;
    }
  },[questions1,questions2,questions3,questions4,questions5, dia]);

  useEffect(()=>{
    switch(dia){
      case 1: setRespuestas(respuestas1); break;
      case 2: setRespuestas(respuestas2); break;
      case 3: setRespuestas(respuestas3); break;
      case 4: setRespuestas(respuestas4); break;
      case 5: setRespuestas(respuestas5); break;
    }
  },[respuestas1,respuestas2,respuestas3,respuestas4,respuestas5, dia]);

  const aceptQuestion = (item) => {

    item['status'] = 'aceptada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{
      
    });
  };

  const rejectQuestion = (item) => {

    item['status'] = 'rechazada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const answerQuestion = (item) => {

    item['status'] = 'contestada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const retrieveQuestion = (item) => {

    item['status'] = 'pendiente';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const eliminarRespuesta = (item) => {
    
    firebase.borrar_objeto(nombre_webinar+`/respuestas-admin${dia}/${item.key}`,()=>{

    });
  };

  const cambiarPreguntaPopUp = (val) => {
    setPopPregunta(val);
    firebase.escribir(nombre_webinar+'/configuracion','1234567890',{encuesta: encuesta,pregunta_popup: val, dia: dia_fire, ver_video: ver_video}, ()=> {
      mensaje('success','Se actualizo la pregunta','');
    } );

  }

  const cambiarEncuesta = (val) => {
    setEncuesta(val);
    firebase.escribir(nombre_webinar+'/configuracion','1234567890',{encuesta: val,pregunta_popup: pop_pregunta, dia: dia_fire, ver_video: ver_video}, ()=> {
      mensaje('success','Se actualizo la pregunta','');
    } );
  }

  useEffect(()=>{
    setDiaFire(1);
    setDia(1);
    if(!mostrar_modal_respuesta){
      
      if(ref_form_respuesta.current){
        
        //ref_form_respuesta.current.resetFields();
      }
    }
    
  },[mostrar_modal_respuesta]);

  const onFinish = (values) => {

    let answer = {...respuesta};
    answer.respuesta = values.respuesta;
    answer.key_pregunta = respuesta.key;
    delete answer.key;
    firebase.escribir(nombre_webinar+`/respuestas-admin${dia}`, undefined, answer, ()=>{
      setMostrarModalRespuesta(false);
      setRespuesta(null);
      //ref_form_respuesta.current.resetFields();
    });
  };


  const editarRespuesta = (values) => {

    let answer = {...respuesta};
    answer.respuesta = values.respuesta;
    firebase.escribir(nombre_webinar+`/respuestas-admin${dia}`, answer.key, answer, ()=>{
      setMostrarModalRespuesta(false);
      setRespuesta(null);
      //ref_form_respuesta.current.resetFields();
    });
  };

    let preguntas_pop_up = preguntas.filter(pregunta => pregunta.dia === dia).map(pregunta => {
      return(
      <Option value={pregunta.num_pregunta}>{pregunta.pregunta}</Option>
      );
    });


    return (
      <>
      <HeaderIngenieriaVehicular />

      <Row>
        <Col span={20} offset={2} style={{display:'none'}}>
          <br /><br /><br /><br />
        <p className="admin_titulo">ENCUESTA</p>
        {pop_pregunta !== -1 &&
        
        <Select defaultValue={pop_pregunta} style={{ width: '100%' }} onChange={cambiarEncuesta}>
          <Option value={false}>Desactivar</Option>
          <Option value={true}>Activar</Option>
        </Select>
        }
        </Col>
      </Row>
      
      <Row>
        <Col span={20} offset={2} style={{display:'none'}}>
          <br /><br /><br /><br />
        <p className="admin_titulo">PREGUNTAS POP-UP</p>
        {pop_pregunta !== -1 &&
        
        <Select defaultValue={pop_pregunta} style={{ width: '100%' }} onChange={cambiarPreguntaPopUp}>
          <Option value={0}>Desactivar</Option>
          { preguntas_pop_up }
        </Select>
        }
        </Col>
      </Row>


      <Row>
        <Col span={20} offset={2} style={{display:'non'}}>
          <br /><br /><br /><br />
        <p className="admin_titulo">USUARIOS CONECTADOS: <strong>{usuarios.length}</strong></p>
        <table style={{display:'none'}}>
        {usuarios_encuesta.filter(usuario => {
          let respuestas = usuario.respuestas ? Object.values(usuario.respuestas) : []
          if(respuestas.length > 0){
            if(respuestas[0] === 'Antes de iniciar el traslado de mercancías.' &&
              respuestas[1] === 'Vehículos ligeros de carga con características menores a un camión C2.'){
                return true
              }
          }

          return false;
        }).map(usuario => {
          let respuestas = usuario.respuestas ? Object.values(usuario.respuestas) : []
          
          let user = usuarios.filter(usuario_datos => {
            
            if(usuario_datos.correo === usuario.correo){
              
              return true
            }else{
              return false
            }
          })
          user = user.length > 0 ? user[0] : {}
          
          return(
            <tr>
              {user &&
              <>
                <td>{user.nombre}</td>
                <td>{user.empresa}</td>
                <td>{user.correo}<br />{usuario.correo}</td>
                <td>{respuestas[0]}</td>
                <td>{respuestas[1]}</td>
              </>
              }
            </tr>
          )
        })}
        </table>

        </Col>
      </Row>

      <Row>
        <Col span={20} offset={2} >
          

          <p className="admin_titulo" style={{display:'none'}}>LIVES</p>
          <Row justify="center" style={{display:'none'}}>
            <Col span={4} style={dia === 1 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(1);}}>DIA 1</Col>
            <Col span={4} style={dia === 2 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setDia(2);}}>DIA 2</Col>
          </Row>
          <br />
          <Row justify="center" align="middle" style={{display:'none'}}>
            {dia === 1 &&
              <>
                <Col span={12} style={video === 1 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setVideo(1);}}>Inauguración y Conferencia Magistral Sr. Edgar Rafael González González Oficial Nacional del Medio Ambiente, Energía y Resiliencia del PNUD</Col>
                <Col span={12} style={video === 2 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setVideo(2);}}>La restauración de los Ecosistemas para contrarrestar los Efectos del Cambio Climático</Col>
              </>
            }

            {dia === 5 &&
              <>
                <Col span={12} style={video === 1 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setVideo(1);}}>Activismo Juvenil en la Preservación del Medio Ambiente</Col>
                <Col span={12} style={video === 2 ? {color:'white', backgroundColor:'#1d70a7',cursor:'pointer'} : {color:'#1d70a7', backgroundColor:'white',cursor:'pointer'}} onClick={()=>{setVideo(2);}}>Equidad de Género en la Preservación del Medio Ambiente</Col>
              </>
            }
          </Row>
          <br /><br />
          


        
        </Col>
      </Row>

      <Row>

        <Col span={24} offset={0}>
          <p className="admin_titulo">PREGUNTAS</p>
        </Col>
        <Col></Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado" style={{color:'rgb(240, 213, 47)'}}>PENDIENTES</p>
            </Col>
            <Col span={24} >
            {questions.filter(pregunta => pregunta.ver_video === video).map((item,idx)=>{

let answers = respuestas.filter(answer => answer.key_pregunta === item.key).map(answer => {
  return(
    <div className="questionAnswer" key={item.key}>
      <p className="question"><span className="nombre_pregunta">{answer.respuesta}</span>
      </p>
      <p className="botones">
      <button className="rejectButton" onClick={()=>{
              eliminarRespuesta(answer);
            }}><DeleteOutlined /></button>
      <button className="editButton" onClick={()=>{
            setRespuesta(answer); setMostrarModalRespuesta(true);
          }}><EditOutlined /></button>
      </p>
    </div>
  );
});


              return  item.status === 'pendiente' ? (
              <div className="questionContainer" key={item.key}>
                <p className="question">
                  <span className="nombre_pregunta">{item.nombre}</span>
                  <br /> {item.organizacion}
                  <br /> <strong> A: {item.ponente}</strong>
                </p>
                <p className="question"><strong>{item.pregunta}</strong></p>
                
                <p className="botones">
                  <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}><CloseCircleOutlined /></button>
                  <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}><CheckCircleOutlined /></button>
                  <button className="responderButton" onClick={()=>{
                  setRespuesta(item); setMostrarModalRespuesta(true);
                }}>RESPONDER</button>
                </p>
                {answers.length > 0 &&
                  <>
                    <br />
            <p className="titulo_respuestas">RESPUESTAS</p>
            {answers}
                  </>
                }
                
                </div>) : null;
            })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado" style={{color:'rgb(65,176,44)'}}>ACEPTADAS</p>
            </Col>
            <Col span={24}>
            {questions.filter(pregunta => pregunta.ver_video === video).map((item,idx)=>{
              

              let answers = respuestas.filter(answer => answer.key_pregunta === item.key).map(answer => {
                return(
                  <div className="questionAnswer" key={item.key}>
                    <p className="question"><span className="nombre_pregunta">{answer.respuesta}</span>
                    </p>
                    <p className="botones">
                    <button className="rejectButton" onClick={()=>{
                            eliminarRespuesta(answer);
                          }}><DeleteOutlined /></button>
                    <button className="editButton" onClick={()=>{
                          setRespuesta(answer); setMostrarModalRespuesta(true);
                        }}><EditOutlined /></button>
                    </p>
                  </div>
                );
              });

          return  item.status === 'aceptada' ? (
            <>
          <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}
            <br /> <strong> A: {item.ponente}</strong>
            </p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}><CloseCircleOutlined /></button>
            <button className="answerButton" onClick={()=>{
              answerQuestion(item);
            }}><CheckCircleOutlined /></button>
            <button className="responderButton" onClick={()=>{
                  setRespuesta(item); setMostrarModalRespuesta(true);
                }}>RESPONDER</button>
            </p>
            {answers.length > 0 &&
                  <>
                    <br />
            <p className="titulo_respuestas">RESPUESTAS</p>
            {answers}
                  </>
                }
            
          </div>
          
          </>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado" style={{color:'rgb(218, 0, 35)'}}>RECHAZADAS</p>
            </Col>
            <Col span={24}>
            {questions.filter(pregunta => pregunta.ver_video === video).map((item,idx)=>{

let answers = respuestas.filter(answer => answer.key_pregunta === item.key).map(answer => {
  return(
    <div className="questionAnswer" key={item.key}>
      <p className="question"><span className="nombre_pregunta">{answer.respuesta}</span>
      </p>
      <p className="botones">
      <button className="rejectButton" onClick={()=>{
              eliminarRespuesta(answer);
            }}><DeleteOutlined /></button>
      <button className="editButton" onClick={()=>{
            setRespuesta(answer); setMostrarModalRespuesta(true);
          }}><EditOutlined /></button>
      </p>
    </div>
  );
});

            return  item.status === 'rechazada' ? (
              <>
            <div className="questionContainer" key={item.key}>
              <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}
              <br /> <strong> A: {item.ponente}</strong>
              </p>
              <p className="question"><strong>{item.pregunta}</strong></p>
              <p className="botones">
                <button className="retrieveButton" onClick={()=>{
              retrieveQuestion(item);
            }}><UndoOutlined /></button>
            </p>
            {answers.length > 0 &&
                  <>
                    <br />
            <p className="titulo_respuestas">RESPUESTAS</p>
            {answers}
                  </>
                }
          </div>
          </>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado" style={{color:'rgb(83,167,225)'}}>CONTESTADAS</p>
            </Col>
            <Col span={24} >
            {questions.filter(pregunta => pregunta.ver_video === video).map((item,idx)=>{

let answers = respuestas.filter(answer => answer.key_pregunta === item.key).map(answer => {
  return(
    <div className="questionAnswer" key={item.key}>
      <p className="question"><span className="nombre_pregunta">{answer.respuesta}</span>
      </p>
      <p className="botones">
      <button className="rejectButton" onClick={()=>{
              eliminarRespuesta(answer);
            }}><DeleteOutlined /></button>
      <button className="editButton" onClick={()=>{
            setRespuesta(answer); setMostrarModalRespuesta(true);
          }}><EditOutlined /></button>
      </p>
    </div>
  );
});

            return  item.status === 'contestada' ? (
              <>
            <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}
            <br /> <strong> A: {item.ponente}</strong></p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}><CheckCircleOutlined /></button>
            </p>
            {answers.length > 0 &&
                  <>
                    <br />
            <p className="titulo_respuestas">RESPUESTAS</p>
            {answers}
                  </>
                }
          </div>
          </>) : null;
        })}
            </Col>
          </Row>
          <br /><br /><br />
        </Col>

        <Modal title="Responde al usuario" 
        visible={mostrar_modal_respuesta} 
        onOk={()=>setMostrarModalRespuesta(false)} 
        onCancel={()=>setMostrarModalRespuesta(false)}
        footer={null}
        >
          {mostrar_modal_respuesta ?
        <Form
        layout="vertical"
      name="basic"
      initialValues={{
        remember: true}}

      ref={ref_form_respuesta}
      onFinish={respuesta && respuesta.key_pregunta ? editarRespuesta : onFinish}
    >
      <p>
        {respuesta && respuesta.pregunta}
      </p>
      <Form.Item
        label="Respuesta"
        name="respuesta"
        rules={[
          {
            required: true,
            message: 'Ingresa tu respuesta',
          },
        ]}
        initialValue={mostrar_modal_respuesta ? (respuesta.respuesta ? respuesta.respuesta : '') : ''}
      >
        <Input.TextArea />
        
      </Form.Item>

      <Form.Item  style={{textAlign:'right'}}>
        <Button type="primary" htmlType="submit">
          Responder
        </Button>
      </Form.Item>
    </Form>
    :
    <>...</>
}
      </Modal>
      
      </Row>
      <FooterIngenieriaVehicular />
    </>)
  
}

export default Admin;