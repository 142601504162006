import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/ingenieria/evento.css';
import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';
import CarouselBanner from './CarouselBanner';
import CarouselBannerMovil from './CarouselBannerMovil';
import CarouselPrincipal from './CarouselPrincipal';

import registro from '../../../src/assets/images/01_impacDemo_home/03 boton-entrar.png';

import entrar from '../../assets/images/01_impacDemo_home/03 boton-entrar.png';
import revivir from '../../assets/images/01_impacDemo_home/03 boton-revivelo.png';
import imglive from '../../assets/images/01_impacDemo_home/03-icono-live.png';

import img1 from '../../assets/images/miniaturas_webinars/mail_michelin_david_zaragoza.jpg';
import img2 from '../../assets/images/miniaturas_webinars/dra_fromow.jpg';
import img3 from '../../assets/images/miniaturas_webinars/bannersWEBINAR_28mayo.jpg';
import img4 from '../../assets/images/miniaturas_webinars/Gestion_Eficiente_imgn.jpg';
import img5 from '../../assets/images/miniaturas_webinars/bannersWEBINAR_04junio.jpg';
import img6 from '../../assets/images/miniaturas_webinars/bannersWEBINARS_manual_11junio.jpg';
import img7 from '../../assets/images/miniaturas_webinars/banner_18junio_sct_antp.jpg';
import img8 from '../../assets/images/miniaturas_webinars/banner_Wsct6julio.jpg';
import img9 from '../../assets/images/miniaturas_webinars/banner_wcummins_datos.jpg';
import img10 from '../../assets/images/miniaturas_webinars/banner_wtmec_sitio.jpg';
import img11 from '../../assets/images/miniaturas_webinars/banner_wsistemgestionpnsv.jpg';
import img12 from '../../assets/images/miniaturas_webinars/banner_webinar_6agosto.jpg';
import reformas_mini from '../../assets/images/miniaturas_webinars/reformas_miniatura.jpg';
import conveersando_mini from '../../assets/images/miniaturas_webinars/CONVESANDO_MINI.jpg';
import vehiculo_alta_mini from '../../assets/images/miniaturas_webinars/vehiculos_alta_mini.jpg';
import ultima_milla_telematica_mini from '../../assets/images/miniaturas_webinars/ultima_milla_telematica_mini.jpg';
import ultima_milla_planeacin_mini from '../../assets/images/miniaturas_webinars/ultima_milla_planeacion_mini.jpg';
import megatendencias_mini from '../../assets/images/miniaturas_webinars/antp_MegaTendencias13oct_p.jpg';
import energia_mini from '../../assets/images/miniaturas_webinars/antp_WebinarTecnicop.jpg';
import subcontratacion_mini from '../../assets/images/miniaturas_webinars/antp_ReformaSubcontratacion_banner.jpg';
import beneficios_mini from '../../assets/images/miniaturas_webinars/antp_webinars_BannerCuadro_2.jpg';
import incoterms_mini from '../../assets/images/miniaturas_webinars/antp_Incoterms_CuadroPostEvento.jpg';
import vacuna_mini from '../../assets/images/miniaturas_webinars/antp_VacunaAnticovid_bannerCuadro_1.jpg';
import carta_porte from '../../assets/images/miniaturas_webinars/antp_CartaPorte_Redes-Whats.jpg';
import electromovilidad from '../../assets/images/miniaturas_webinars/antp_Electromovilidad_BannerCuadro_.jpg';
import carta_porte_2_0 from '../../assets/images/miniaturas_webinars/antp_CartaPorteV2_CuadroWEB_.jpg';
import tv_carta_porte from '../../assets/images/miniaturas_webinars/antp_CartaPorte3_ReviveLaTransmision.jpg';
import seguridad_privada_prev from '../../assets/images/miniaturas_webinars/antp_SeguridadPrivada_MINIATURA_.jpg';
import cfdi_2024 from '../../assets/images/miniaturas_webinars/antp_CartaPorte_27SEP_bannerWEBpromo.jpg';
import nom_015 from '../../assets/images/miniaturas_webinars/antp_nomo15_17oct_registrominiatura.jpg';

const progra = [
  {hora:'12:00', fecha:'17 octubre 2023', live:'', nombre:'1-coloquio-transporte-multimodal-SCT-2-2022 - Condiciones de seguridad en la estiba y sujeción de la carga', descripcion:'', dia:'1', img:nom_015, url:'/1-coloquio-transporte-multimodal-sct-2-2022', link:revivir},
  {hora:'11:00', fecha:'27 septiembre 2023', live:'', nombre:'ESTÁNDAR PARA EL LLENADO DEL CFDI CON COMPLEMENTO CARTA PORTE', descripcion:'', dia:'1', img:cfdi_2024, url:'/registro/cfdi-cpp-2024', link:revivir},
  {hora:'11:00', fecha:'16 febrero 2023', live:'', nombre:'SEGURIDAD PRIVADA EN LA PREVENCIÓN DEL ROBO AL AUTOTRANSPORTE DE CARGA', descripcion:'', dia:'1', img:seguridad_privada_prev, url:'/seguridad-privada-en-la-prevencion/conferencias', link:revivir},
  {hora:'12:00', fecha:'27 enero 2021', live:'', nombre:'TALLER VIRTUAL: CFDI CON COMPLEMENTO CARTA PORTE, RESOLUCIÓN MISCELÁNEA FISCAL PARA 2022', descripcion:'', dia:'1', img:tv_carta_porte, url:'/taller-vitual-cfdi-carta-porte/conferencias', link:revivir},
  {hora:'11:00', fecha:'3 diciembre 2021', live:'', nombre:'COMPLEMENTO CARTA PORTE PARA EL TRANSPORTE DE MERCANCÍAS VERSIÓN 2.0', descripcion:'', dia:'1', img:carta_porte_2_0, url:'/carta-porte-2-0/conferencias', link:revivir},
  {hora:'18:00', fecha:'13 octubre 2021', live:'', nombre:'LA ELECTROMOVILIDAD EN LA CADENA DE SUMINISTRO', descripcion:'', dia:'1', img:electromovilidad, url:'/la-electromovilidad/conferencias', link:revivir},
  {hora:'11:00', fecha:'6 octubre 2021', live:'', nombre:'COMPLEMENTO CARTA PORTE PARA EL TRANSPORTE DE MERCANCÍAS', descripcion:'', dia:'1', img:carta_porte, url:'/carta-porte/conferencias', link:revivir},
  {hora:'12:00', fecha:'9 agosto 2021', live:'', nombre:'LA VACUNA ANTICOVID', descripcion:'', dia:'1', img:vacuna_mini, url:'/la-vacuna-anticovid/conferencias', link:revivir},
  {hora:'12:30', fecha:'28 julio 2021', live:'', nombre:'INCOTERMS 2020 | EN SU APLICACIÓN A LA OPERACIÓN ADUANERA DEL TRANSPORTE DE CARGA', descripcion:'', dia:'1', img:incoterms_mini, url:'/incoterms-2020/conferencias', link:revivir},
  {hora:'11:00', fecha:'7 julio 2021', live:'', nombre:'BENEFICIOS DE IMPLEMENTAR LA CERTIFICACIÓN CTPAT EN EL TRANSPORTE DE CARGA', descripcion:'', dia:'1', img:beneficios_mini, url:'/certificacion-ctpat/conferencias', link:revivir},
    {hora:'11:00', fecha:'16 abril 2021', live:'', nombre:'REFORMA EN MATERIA DE SUBCONTRATACIÓN LABORAL (OUTSOURCING)', descripcion:'', dia:'1', img:subcontratacion_mini, url:'/desubcontratacion-laboral/conferencias', link:revivir},
    {hora:'12:00', fecha:'15 octubre 2020', live:'', nombre:'LA ENERGÍA DEL FUTURO PARA EL TRANSPORTE DE MERCANCÍAS', descripcion:'', dia:'1', img:energia_mini, url:'/energia-del-futuro/conferencias', link:revivir},
    {hora:'11:00', fecha:'14 october 2020', live:'', nombre:'MEGATRENDS IN THE MARITIME TRANSPORT OF CONTAINERS', descripcion:'', dia:'1', img:megatendencias_mini, url:'/megatendencias-14-en/conferencias', link:revivir},
    {hora:'11:00', fecha:'14 de octubre 2020', live:'', nombre:'MEGATENDENCIAS EN EL TRANSPORTE MARÍTIMO DE CONTENEDORES', descripcion:'', dia:'1', img:megatendencias_mini, url:'/megatendencias-14-esp/conferencias', link:revivir},
    {hora:'11:00', fecha:'13th october 2020', live:'', nombre:'MEGATRENDS IN THE MARITIME TRANSPORT OF CONTAINERS', descripcion:'', dia:'1', img:megatendencias_mini, url:'/megatendencias-13-en/conferencias', link:revivir},
    {hora:'11:00', fecha:'13 de octubre 2020', live:'', nombre:'MEGATENDENCIAS EN EL TRANSPORTE MARÍTIMO DE CONTENEDORES', descripcion:'', dia:'1', img:megatendencias_mini, url:'/megatendencias-13-esp/conferencias', link:revivir},
    {hora:'12:00', fecha:'30 de septiembre 2020', live:'', nombre:'ÚLTIMA MILLA: PLANEACIÓN DE RUTAS DE ENTREGA Y CÓMO OBTENER BENFICIOS REALES', descripcion:'', dia:'2', img:ultima_milla_planeacin_mini, url:'/ultima-milla-planeacion/conferencias', link:revivir},
    {hora:'12:00', fecha:'24 de septiembre 2020', live:'', nombre:'ÚLTIMA MILLA: TELEMÁTICA Y GPS. DISMINUCIÓN DE COSTOS OPERATIVOS.', descripcion:'', dia:'1', img:ultima_milla_telematica_mini, url:'/ultima-milla-telematica/conferencias', link:revivir},
    {hora:'11:00', fecha:'4 de septiembre 2020', live:'', nombre:'VEHÍCULOS DE ALTA CAPACIDAD EN EL MUNDO.', descripcion:'', dia:'1', img:vehiculo_alta_mini, url:'/vehiculos-alta/conferencias', link:revivir},
    {hora:'11:00', fecha:'1º de septiembre 2020', live:'', nombre:'CONVERSANDO POR LA SEGURIDAD EN LA CADENA DE SUMINISTRO ISO 28000:2007.', descripcion:'', dia:'1', img:conveersando_mini, url:'/conversando-seguridad/conferencias', link:revivir},
    {hora:'11:00', fecha:'12 de agosto 2020', live:'', nombre:'REFORMAS PENALES Y SUS BENEFICIOS PARA LA ATENCIÓN DEL ROBO AL AUTOTRANSPORTE DE CARGA (12-AGO).', descripcion:'', dia:'2', img:reformas_mini, url:'/reformas-penales-12/conferencias', link:revivir},
    {hora:'11:00', fecha:'11 de agosto 2020', live:'', nombre:'REFORMAS PENALES Y SUS BENEFICIOS PARA LA ATENCIÓN DEL ROBO AL AUTOTRANSPORTE DE CARGA (11-AGO).', descripcion:'', dia:'1', img:reformas_mini, url:'/reformas-penales-11/conferencias', link:revivir},
    {hora:'12:00', fecha:'6 de agosto 2020', live:'', nombre:'IMPORTANCIA DE UNA BUENA INGENIERÍA VEHICULAR PARA LA OPTIMIZACIÓN DE LOS COSTOS DEL TRANSPORTE CARRETERO', descripcion:'', dia:'1', img:img12, url:'/ingenieria-vehicular/conferencias', link:revivir},
    {hora:'11:00', fecha:'21 de julio 2020', live:'', nombre:'SISTEMA DE GESTIÓN EN SEGURIDAD VIAL', descripcion:'', dia:'1', img:img11, url:'/seguridad-vial/conferencias', link:revivir},
    {hora:'11:00', fecha:'17 de julio 2020', live:'', nombre:'EL TRANSPORTE DE CARGA EN EL T-MEC', descripcion:'', dia:'1', img:img10, url:'/t-mec/conferencias', link:revivir},
    {hora:'11:00', fecha:'7 de julio 2020', live:'', nombre:'TRANSFORMANDO LOS NEGOCIOS EN UN ÉXITO', descripcion:'', dia:'1', img:img9, url:'/transformando-negocios/conferencias', link:revivir},
    {hora:'12:00', fecha:'6 de julio 2020', live:'', nombre:'TRÁMITES EN LÍNEA Y NUEVO SISTEMA DE CITAS ANTE LA SCT: NUEVOS REQUISITOS, PLAZOS Y PROCEDIMIENTOS', descripcion:'', dia:'1', img:img8, url:'/tramites-en-linea/conferencias', link:revivir},
    {hora:'11:00', fecha:'18 de junio 2020', live:'', nombre:'SISTEMA NACIONAL DE INDICADORES FERROVIARIOS', descripcion:'', dia:'1', img:img7, url:'/indicadores-ferroviarios/conferencias', link:revivir},
    {hora:'16:30', fecha:'11 de junio 2020', live:'', nombre:'MANUAL DE PREVENCIÓN Y REACCIÓN DEL DELITO DE ROBO AL AUTOTRANSPORTE DE CARGA', descripcion:'', dia:'1', img:img6, url:'/manual-modulo3/conferencias', link:revivir},
    {hora:'16:30', fecha:'4 de junio 2020', live:'', nombre:'MANUAL DE PREVENCIÓN Y REACCIÓN DEL DELITO DE ROBO AL AUTOTRANSPORTE DE CARGA', descripcion:'', dia:'1', img:img5, url:'/manual-modulo2/conferencias', link:revivir},
    {hora:'16:30 ', fecha:'28 de mayo 2020', live:'', nombre:'GESTIÓN EFICIENTE DE TALLER UNA INVERSIÓN CONSCIENTE PARA TU NEGOCIO', descripcion:'', dia:'1', img:img4, url:'gestion-eficiente/conferencias', link:revivir},
    {hora:'16:30 ', fecha:'28 de mayo 2020', live:'', nombre:'MANUAL DE PREVENCIÓN Y REACCIÓN DEL DELITO DE ROBO AL AUTOTRANSPORTE DE CARGA', descripcion:'', dia:'1', img:img3, url:'/manual-modulo1/conferencias', link:revivir},
    {hora:'16:30 - 18:00', fecha:'21 de mayo 2020', live:'', nombre:'GUÍA Y LINEAMIENTOS ANTE LA APERTURA A UNA NUEVA NORMALIDAD Y RESPONSABILIDAD EMPRESARIAL EN MATERIA LABORAL DESPUÉS DEL CONFINAMIENTO', descripcion:'', dia:'1', img:img2, url:'/guia-y-lineamientos/conferencias', link:revivir},
    {hora:'11:00', fecha:'14 de mayo 2020', live:'', nombre:'TENDENCIAS Y POTENCIALES EN EL CONSUMIDOR DURANTE Y DESPUÉS DEL COVID19', descripcion:'', dia:'1', img:img1, url:'/tendencias-potenciales/conferencias', link:revivir}
];


const EventoInicio = () =>{

  const { usuario, datos } = useContext(Auth);

  useEffect(()=>{
    if(usuario){
      //window.location.href = "/en-vivo";
    }
  },[usuario]);

  let visualizar_programa = progra.map((seccion)=>{
    return(
      <div className="info-horario">
        
        <Row justify="center" align="middle">
          <Col xs={10} md={3}>
            <p style={{ textAlign:'left' }}>
              <img alt="" style={{ width:'50%' }} src={seccion.live}/><br/>
              <span className="info-hora">{seccion.hora}</span><br />
              <span className="info-fecha">{seccion.fecha}</span>
            </p>
          </Col>
          
          <Col xs={10} md={11}>
            <p>
            <span className="info-nombre"><strong>{seccion.nombre}</strong></span><br />
            <span className="info-descripcion"><strong>{seccion.descripcion}</strong></span></p>
          </Col>
          <Col xs={10} sm={8} md={3}>
            <img alt="" style={{ width:'100%' }} className="info-video" src={seccion.img} />
          </Col>
          <Col xs={10} sm={8} md={3}>
            <a href={seccion.url}>
              <img alt="" style={{ width:'70%' }} src={seccion.link}/>
            </a>
          </Col>
          <Col md={20}>
            <hr style={{ color:'#000000' }} />
          </Col>
        </Row>
      </div>
    );
  });
  
  return(
    <>
    <HeaderIngenieriaVehicular />
    <Row justify="center" align="middle">

      <Col span={24} >
        <CarouselPrincipal />
      </Col>
      <Col span={24} className="programa" id="programa">
        <Row justify="center" align="middle">
          <Col span={18} align="left">
            <p className="tituPrograma"><strong>WEBINARS ANTERIORES</strong></p>
          </Col>
          <Col span={24} >
            <Row justify="center" align="middle">
              <Col span={18} className="divPrograma">
                {visualizar_programa}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <br/><br/><br />
          </Col>
        </Row>
      </Col>
    </Row>
    <FooterIngenieriaVehicular />


    </>
  );
}

export default EventoInicio;