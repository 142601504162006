import React from 'react';
import { Row, Col } from 'antd';


import img_usuario from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-ID.png';
import img_pass from '../../assets/images/webinars/vehiculos_alta_capacidad/02-icono-Password.png';

import oct_6 from '../../assets/images/AccederOctubre6.png';
import oct_1314 from '../../assets/images/AccederOctubre13y14.png';


const RegistroCompletoVehiculosAlta = (props) => {

    const {correo, pass} = props;

    return(
        <Row>
            <Col span={24}>
                <p style={{color:'#32abdf', fontWeight:'bold', textAlign:'left', fontSize:'40px'}}>
                    REGISTRO EXITOSO
                    <hr style={{border:'1px solid gray'}} />
                </p>
                <p style={{textAlign:'left', fontSize:'20px'}}>
                    En breve recibirá un correo de confirmación a la siguiente dirección:<br />
                    <span style={{color:'#32abdf'}}><strong>{ correo}</strong></span>
                </p>

                <p style={{color:'#32abdf', textAlign:'left', fontSize:'20px'}}>
                Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje
                haya sido enviado a la carpeta de SPAM / NO DESEADOS.
                </p>
                <br />
                <Row>
                    <Col span={12}>
                        <a href="https://thelogisticsworld.com/virtual-event/conferencias/los-nuevos-retos-en-el-transporte-maritimo-de-contenedores" target="_blank">
                            <img src={oct_6} style={{width:'100%'}} />
                        </a>
                    </Col>
                    <Col span={12}>
                        <a href="https://www.antp.org.mx/megatendencias" target="_blank">
                            <img src={oct_1314} style={{width:'100%'}} />
                        </a>
                    </Col>
                </Row>
                <br />
                <p style={{color:'#0d3159', textAlign:'left', fontSize:'20px'}}>
                    Instrucciones para acceder el 13 y 14 de octubre.
                </p>
                
                <Row style={{color:'#0d3159', fontSize:'20px'}}>
                    <Col span={1} style={{color:'#32abdf'}}>
                        <p><strong>1</strong></p>
                    </Col>
                    <Col span={23} style={{textAlign:'left'}}>
                        <p>Ingresa a  <strong>https://www.antp.org.mx/megatendencias</strong></p>
                    </Col>  
                    <Col span={1} style={{color:'#32abdf'}}>
                        <p><strong>2</strong></p>
                    </Col>
                    <Col span={23} style={{textAlign:'left'}}>
                        <p>Copia tu  <strong>usuario (correo electrónico)</strong> y pégalo en la casilla correspondiente.</p>
                    </Col>  
                    <Col span={1} style={{color:'#32abdf'}}>
                        <p><strong>3</strong></p>
                    </Col>
                    <Col span={23} style={{textAlign:'left'}}>
                        <p>Repite el paso anterior con tu <strong>primer apellido.</strong></p>
                    </Col>  
                    <Col span={1} style={{color:'#32abdf'}}>
                        <p><strong>4</strong></p>
                    </Col>
                    <Col span={23} style={{textAlign:'left'}}>
                        <p>Da clic en  <strong>ingresar</strong> y disfruta del evento.</p>
                    </Col>  
                </Row>

                <p style={{color:'#32abdf', textAlign:'left', fontSize:'20px'}}>
                    <strong>Tus datos de acceso</strong>
                </p>
                
                <Row justify="space-around" align="middle">
                    <Col span={2}>
                        <p><img src={img_usuario} style={{width:'100%'}} /></p>
                    </Col>
                    <Col span={22} style={{textAlign:'left', fontSize:'20px'}}>
    <p><strong>{correo}</strong></p>
                    </Col>  
                    <Col span={2}>
                        <p><img src={img_pass} style={{width:'100%'}} /></p>
                    </Col>
                    <Col span={22} style={{textAlign:'left', fontSize:'20px'}}>
    <p><strong>{pass}</strong></p>
                    </Col>  
                    
                </Row>

            </Col>
            <Col span={24}>
                <br /><br /><br /><br />
            </Col>
        </Row>
    );
}

export default RegistroCompletoVehiculosAlta;