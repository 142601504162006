import React, { useEffect, useContext ,useState, useRef} from "react";

import {  Row, Col, Radio, Spin, Modal, Button } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import {Preguntas} from '../conferencias/Preguntas'; 
import {Estadisticas} from '../conferencias/Estadisticas';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';
import '../../assets/css/preguntas_popup.css';

import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

import src_foro from '../../assets/images/webinars/vehiculos_alta_capacidad/03-banner-XXFORO.jpg';
import src_foro_movil from '../../assets/images/webinars/vehiculos_alta_capacidad/banner_movil_foro.jpg';
import src_aniversario from '../../assets/images/webinars/vehiculos_alta_capacidad/03-banner-25ANIVERSARIO.jpg';
import conoce_ponentes from '../../assets/images/webinars/vehiculos_alta_capacidad/03-ponentes-vehiculos.jpg';

const nombre_evento = 'vehiculos-alta-capacidad';

const app = new Firebase();

let respuesta_elegida = null;

let key = '';


const IngenieriaVehicular =({history}) =>{

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_pregunta, setMostrarPregunta] = useState(false);
    const [preguntas_pop_up, setPreguntasPopUp] = useState([]);
    const [pregunta_pop_up_activa, setPreguntaPopUpActiva] = useState(0);
    const [respuesta_usuario, setRespuestaUsuario] = useState(null);

    const ref_respuesta_popup = useRef(null);


    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                    app.obtener_objetos(nombre_evento+'/preguntas', false, (datos) => {
                        
                        setPreguntas(datos);
                        app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                            setEstadisticas(datos);
                            setCargando(false);
                            if (typeof existe_respuestas !== 'undefined') {
                                setEtapa(2);
                            } else {
                                setEtapa(1);
                            }
                        });
                    })
                
                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
            break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            correo: datos.correo,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {
        
        if (usuario===null) {
            
        }else{
            if(Object.entries(datos).length !== 0){
                app.obtener_objeto(nombre_evento+'/usuarios-encuesta', 'correo', usuario.email, siguienteEtapa);
            }
            app.obtener_objetos(nombre_evento+'/preguntas-publico',true, (data) => {
                setPreguntasPonente(data);
            });

            app.obtener_objeto(nombre_evento+'/preguntas-popup-publico','correo', usuario.email,(data) => {
                setRespuestaUsuario(data);
            });
        }
        
    }, [history, usuario, datos]);

    useEffect(() => {
        app.obtener_objetos(nombre_evento+'/preguntas-popup',false, (data) => {
            setPreguntasPopUp(data);
        });
    },[]);

    useEffect(() => {
        app.obtener_objetos(nombre_evento+'/configuracion',true,(data) => {
            
            if(data[0].pregunta_popup !== 0){
                setPreguntaPopUpActiva(data[0].pregunta_popup);
                if(usuario){
                    
                    if (respuesta_usuario){
                        
                        if (respuesta_usuario['respuesta'+data[0].pregunta_popup] !== undefined){
                            setMostrarPregunta(false);
                        }else{
                            setMostrarPregunta(true);
                        }
                    }else{
                        setMostrarPregunta(false);
                    }
                    
                }else{
                    setMostrarPregunta(false);
                }
            }else{
                setPreguntaPopUpActiva(data[0].pregunta_popup);
                setMostrarPregunta(false);
            }
            
        });
    },[usuario, respuesta_usuario]);

    const elegirRespuesta = (respuesta) => {
        respuesta_elegida = respuesta;
    }

    const enviarRespuestaPopUp = () => {
        if(respuesta_elegida !== null){
            
            let nuevo_respuesta_usuario = Object.assign({} , respuesta_usuario);
            nuevo_respuesta_usuario['respuesta'+pregunta_pop_up_activa] = respuesta_elegida.target.value;
            app.escribir(nombre_evento+'/preguntas-popup-publico', respuesta_usuario.key, nuevo_respuesta_usuario, (data) => {
                setRespuestaUsuario(data);
                respuesta_elegida = null;
                setMostrarPregunta(false);
            })
        }else{

        }
        
    }

    return (
        <>
        <HeaderIngenieriaVehicular />
        
        <div className="cuerpo_vehiculos">
            <Row justify="" align="">
            <Col xs={0}  md={{span:20, offset:2}} style={{textAlign:'center'}}>
                <a target="_blank" href="https://www.antp.org.mx/foro"><img src={src_foro} style={{width:'100%'}} /></a>
            </Col>
            <Col  xs={{span:20, offset:2}} md={0}  style={{textAlign:'center'}}>
                <a target="_blank" href="https://www.antp.org.mx/foro"><img src={src_foro_movil} style={{width:'100%'}} /></a>
            </Col>
            <Col span={24}>
                <Live url="https://www.youtube.com/embed/Xcx7bx-L1qQ?rel=0&autoplay=1" urlyt="https://youtu.be/Xcx7bx-L1qQ" urlfb={null} urltw={null} webinar={nombre_evento} comentarios={preguntas_ponente} />
            </Col>
            <Col xs={24} >
                <br />
            </Col>
            </Row>
            <Row>
                <Col xs={{span:20, offset:2}} sm={{span:20, offset:2}} md={{span:20, offset:2}} lg={{span:14, offset:2}} className="contenedor_graficas_">
                { usuario && 
            <Row>
                <Col span={24}>
            <Spin tip="Cargando..." spinning={cargando}>
            {
                    etapa === 1 ?
                        (
                            <Col span={24} offset={0}>
                                <Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />
                            </Col>
                        )
                        :
                        (
                            <Col xs={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 20, offset: 2 }}>
                                <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
                            </Col>
                        )
                }
                </Spin>
                </Col>
                </Row>
                }
                </Col>
                <Col xs={{span:0, offset:0}} sm={{span:0, offset:0}} md={{span:10, offset:7}} lg={{span:6, offset:0}} className="">
                <Row>
                    <Col xs={24} lg={{span:23, offset:1}}>
                    <img src={src_aniversario} style={{width:'100%'}} />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Row>
            <Col xs={24} lg={0}>
                    <br />
                </Col>
                <Col xs={{span:0}} md={{span:20, offset:2}}>
                    <p className="concoce_ponentes"><strong>CONOCE A LOS PONENTES</strong></p>
                    <img src={conoce_ponentes} style={{ width:'100%'}} />
                </Col>
            </Row>
        </div>
        
        <FooterIngenieriaVehicular />

        <Modal
          title="Encuesta"
          visible={mostrar_pregunta}
          onOk={() => setMostrarPregunta(false)}
          onCancel={() => setMostrarPregunta(false)}
          footer={null}
          width='600px'
        >

            <>
                
                    {preguntas_pop_up.filter(pregunta => {
                        if(pregunta.num_pregunta === pregunta_pop_up_activa){
                            return true;
                        }else{
                            return false;
                        }
                    }).map(pregunta => {
                        return(
                            <p className="texto_pregunta_popup">{pregunta.pregunta}</p>
                        );
                    })}
                

                
                    {
                        preguntas_pop_up.filter(pregunta => {
                            if(pregunta.num_pregunta === pregunta_pop_up_activa){
                                return true;
                            }else{
                                return false;
                            }
                        }).map(pregunta => {

                            let respuestas = Object.values(pregunta.respuestas).map(respuesta => {
                                return(
                                    <Radio className="texto_respuesta_popup" value={respuesta.respuesta}>
                                    {respuesta.respuesta}
                                    </Radio>
                                );
                            });

                            return(
                                <Radio.Group onChange={elegirRespuesta}>
                                    { respuestas }                                    
                                </Radio.Group>
                            );
                        })
                    
                    }
                
                    <br />
                    <p style={{textAlign:'right'}}>
                    <Button onClick={enviarRespuestaPopUp}>Enviar</Button>
                    </p>
                
                </>

               
            
          
        </Modal>

        

                </>
    );

}

export default IngenieriaVehicular;