import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Modal, Button } from "antd";
import "../../../assets/css/ingenieria/evento.css";
import ima from "../../../../src/assets/images/eventos/01-ingenierIa-vehicular/01-logo-antp.png";
import registro from "../../../../src/assets/images/01_impacDemo_home/03 boton-entrar.png";
import { Auth } from "../../../utils/AuthContext";
import Login from "../../registro/Login";
import LoginLibre from "../../registro/LoginLibre";
import { MenuOutlined } from "@ant-design/icons";

import img_entrar_cin_login from "../../../assets/images/eventos/energia_del_futuro/03 BotonEntrarSinRegistro.png";

const secciones = [
  { nombre: "Inicio", url: "/inicio" },
  { nombre: "Registro", url: "/registro/1-coloquio-transporte-multimodal" },
];

const HeaderIngenieriaVehicular = ({ history }) => {
  let location = useLocation();
  console.log("la url");
  console.log(location.pathname);

  const { usuario } = useContext(Auth);
  const [en_vivo, setEnVivo] = useState(false);
  const [encuesta, setEncuesta] = useState(false);
  const [modal_login, setModalLogin] = useState(false);
  const [mostrar_menu, setMotrarMenu] = useState(false);

  const direccionar = (encuesta) => {
    console.log(encuesta);
    setModalLogin(false);
    window.location.href = "/en-vivo";
    /*if(encuesta !== undefined){
      if(encuesta === 0){
        setEncuesta(true);
      }else{
        setEnVivo(true);
      }
    }*/
  };

  let visualizar_secciones = secciones.map((seccion) => {
    return (
      <Link
        className={
          location.pathname === seccion.url ? "titles_subrayado" : "titles"
        }
        to={seccion.url}
      >
        <span className="seccion">{seccion.nombre}</span>
      </Link>
    );
  });

  let visualizar_secciones_movil = secciones.map((seccion) => {
    return (
      <Col span={24} className="contenedor_link_movil">
        <Link
          className={
            location.pathname === seccion.url ? "titles_subrayado" : "titles"
          }
          to={seccion.url}
        >
          <span className="seccion">{seccion.nombre}</span>
        </Link>
      </Col>
    );
  });

  useEffect(() => {
    if (encuesta) {
      //window.location.href = "/constancia";
      window.location.href = "/en-vivo";
    } else {
      if (en_vivo) {
        //window.location.href = "/constancia";
        window.location.href = "/en-vivo";
      }
    }
  }, [en_vivo, encuesta]);

  useEffect(() => {
    if (location.pathname === "/registro/1-coloquio-transporte-multimodal" || location.pathname === "/en-vivo") {
      setModalLogin(false);
    } else if (!usuario) {
      setModalLogin(false); //true
    }
    if(!usuario){
        //setModalLogin(true);//comentar
      }
  }, []);

  return (
    <Row align="middle">
      <Col
        xs={{ span: 6, offset: 0 }}
        md={{ span: 0, offset: 0 }}
        style={{ padding: "15px" }}
      >
        <img alt="logo" style={{ width: "100%" }} src={ima} />
      </Col>
      <Col
        xs={{ span: 0, offset: 0 }}
        md={{ span: 4, offset: 0 }}
        style={{ padding: "15px" }}
      >
        <img alt="logo" style={{ width: "100%" }} src={ima} />
      </Col>

      <Col
        xs={{ span: 0, offset: 0 }}
        md={{ span: 20, offset: 0 }}
        style={{ textAlign: "right", padding: "15px" }}
      >
        {visualizar_secciones}
        {usuario === null ? (
          <>
             <span style={{ display: "none" }}>
              <img
                alt="login"
                src={registro}
                style={{ width: "100px" }}
                onClick={() => setModalLogin(true)}
              />
            </span> 
          </>
        ) : (
          <>
            <Link to="/en-vivo" className="titles" style={{ display: "none" }}>
              <span className="seccion">En vivo</span>
            </Link>
            <Link
              to="/cerrar-sesion"
              className="titles"
              style={{ display: "none" }}
            >
              Cerrar sesión
            </Link>
          </>
        )}
      </Col>
      <Col
        xs={{ span: 6, offset: 12 }}
        md={{ span: 0, offset: 0 }}
        style={{ padding: "15px", textAlign: "right" }}
      >
        <MenuOutlined
          style={{ fontSize: "25px", fontWeight: "bold" }}
          onClick={() => setMotrarMenu(!mostrar_menu)}
        />
      </Col>
      {mostrar_menu && (
        <Col
          xs={{ span: 24, offset: 0 }}
          md={{ span: 0, offset: 0 }}
          style={{ display: "none" }}
          className="contenedor_menu_col"
        >
          <Row className="contenedor_menu_row">
            {visualizar_secciones_movil}
            <Col span={24} className="contenedor_link_movil">
              {usuario === null ? (
                <span style={{ display: "none" }}>
                  <img
                    alt="login"
                    src={registro}
                    style={{ width: "100px" }}
                    onClick={() => setModalLogin(true)}
                  />
                </span>
              ) : (
                <>
                  <Link
                    to="/cerrar-sesion"
                    className="titles"
                    style={{ display: "none" }}
                  >
                    Cerrar sesión{" "}
                  </Link>
                  <br />
                  <Link
                    to="/en-vivo"
                    className="titles"
                    style={{ display: "none" }}
                  >
                    En vivo
                  </Link>
                </>
              )}
            </Col>
          </Row>
        </Col>
      )}

      {<Row style={{}}>
           
            <Col xs={{span:12, offset:0}} md={{span:14, offset:0}} style={{display:'none'}}>
              <p style={{color:'#112f6d'}}>Inicio</p>
            </Col>
            <Col xs={{span:12, offset:0}} md={{span:10, offset:0}} style={{display:'none'}}>
              <img src={img_entrar_cin_login} style={{width:'100%', cursor:'pointer'}} onClick={() => {setModalLogin(false)}} />
            </Col>
    </Row>}
      <Modal
        title={null}
        visible={modal_login}
        onOk={() => setModalLogin(false)}
        onCancel={() => setModalLogin(false)}
        closable={false}
        footer={null}
        style={{}}
        bodyStyle={{}}
      >
        <Login
          onResponse={(encuesta) => {
            direccionar(encuesta);
          }}
        />
        {/*<LoginLibre onResponse={(encuesta) => { direccionar(encuesta);}} />
        */}
        
      </Modal>
    </Row>
  );
};

export default HeaderIngenieriaVehicular;
