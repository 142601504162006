import React, { useEffect, useState } from "react";
import Firebase from "./firebaseConfig";
import Cargando from '../componentes/auth/Cargando'

export const Auth = React.createContext();

const app = new Firebase();

const nombre_evento = '1-coloquio-transporte-multimodal';

export const AuthContext = ({ children }) => {
    const [usuario, setUsuario] = useState(null);
    const [showChild, setShowChild] = useState(false);
    const [datos, setDatos] = useState({});

    useEffect(()=>{console.log(datos);},[datos]);
    
    useEffect(() => {
        app.app.auth().onAuthStateChanged(function(user) {

            setUsuario(user);
            setShowChild(true);
            setDatos(JSON.parse(localStorage.getItem('usuario')));
            /*if(user){
                app.obtener_webinars_usuario(nombre_evento+'/usuarios','correo',user.email,(data) => {
                    setDatos(data);
                    
                });
            }*/

            


        });
    }, []);

    if (!showChild) {
        return <Cargando/>;
    } else {
        return (
            <Auth.Provider
                value={{
                    usuario, datos
                }}
            >
                {children}
            </Auth.Provider>
        );
    }
};
